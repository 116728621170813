import { AppConst } from '@/store/modules/constants'
import {
  LibraryCategories,
  LibraryCategoryStates,
  LibraryEntry,
} from '@/store/modules/teamlibrary/types'
import { ListHelpers } from '@/utils/list-helpers'

export default {
  updateModelsLibrary(state: any, responseModels: any) {
    state.modelsLibrary = responseModels
    this.updateHelpHeroData(AppConst.HelpHero.helpHeroModelsCountKey, responseModels.length)
  },
  updateLibraryCategories(state: any, responseLibCategories: LibraryCategories) {
    state.libraryCategories = responseLibCategories
  },
  updateLibraryCategoryStates(state: any, responseStates: LibraryCategoryStates) {
    state.libraryCategoryStates = responseStates
  },
  updateLibraryEntry(state: any, entry: LibraryEntry) {
    state.libraryEntry = entry
  },
  deleteFromModelsLibraryById(state: any, id: string) {
    ListHelpers.deleteById(state.modelsLibrary, 'Id', id)
  },
  resetLibraryData(state: any) {
    state.modelsLibrary = []
    state.libraryEntry = {
      DisplayName: '',
      Tags: [],
      Categories: [],
    }
  },
}
