import { VersionConstants } from '@/store/modules/version/constants'

export namespace AppConst {
  export const apiDomain = (process.env.VUE_APP_AUDIENCE as string).replace(/\/$/, '') // Remove trailing slashes from the audience
  // export const apiDomain = 'http://localhost:4443'

  export enum APIEndpoint {
    myJigsV2Url = '/v2/me/jigs',
    jigsV1Url = '/jigs/v1',
    jigsV2Url = '/jigs/v2',
    meJigsV2Url = '/me/jigs/v2',
    jigMetadataV1 = 'metadata/v1',
    myJigsV2UrlMetrics = '/v2/me/jigs/metrics',
    publicModelsUrl = '/library',
    myJigsUrl = '/me/jigs',
    libraryCategoriesUrl = '/library/categories',
    libraryCategoriesUrlStatesUrl = `/states`,
    tenantsV1Url = '/tenants/v1',
    tenantsJigsUrl = '/jigs/v2',
    tenantsJigsUrlImagesV1Url = `/images/v1`,
    usersV1Url = '/users/v1',
    statisticsUrl = '/statistics/reports',
    billingUrl = '/billing',
    billingCheckoutUrl = `/stripe/create-checkout-session`,
    tenantSetupJobsUrl = '/tenant-setup-jobs/v1',
    completedTenantSetupJobsUrl = '/secure-tenant-setup-jobs/v1/completed',
    versionUrl = '/version/latest',
    hubSpotVisitorIdTokenUrl = '/hs/v1/visitoridtoken',
    createNewVersionUrl = '/version',
    getSmallQRCodeUrl = '/qrcode/v1/small/v1',
    getLargeQRCodeUrl = '/qrcode/v1/large/v1',
    createStripeCheckoutUrl = '/billing/stripe/create-checkout-session',
    createStripeCustomerPortalUrl = '/billing/stripe/create-customer-portal-session',
    branchIODownloadUrlsV1 = '/branchio/link-download-urls/v1',
    dynaconfConfig = '/configs/v1',
  }

  export const libraryCategoryStatesUrl = `${APIEndpoint.libraryCategoriesUrl}${APIEndpoint.libraryCategoriesUrlStatesUrl}`
  export const tenantsImagesV1Url = `${APIEndpoint.tenantsV1Url}${APIEndpoint.tenantsJigsUrlImagesV1Url}`
  export const checkoutUrl = `${APIEndpoint.billingUrl}${APIEndpoint.billingCheckoutUrl}`
  export const communitySlackWebhook = process.env.VUE_APP_SLACK_WEBHOOK || ''
  export const hubspotId = process.env.VUE_APP_HUBSPOT_ID || ''

  export enum StoreHelper {
    loadingMutationName = 'utils/changeLoadingState',
    loadingModalMutationName = 'utils/changeModalLoadingState',
    isStatisticsLoadingMutationName = 'jig/changeIsStatisticsLoadingState',
    SetApiErrorMutationName = 'utils/setApiError',
  }

  export enum RequestHeaders {
    CustomHeaderKeyTenantId = 'X-Jig-Tenant-Id',
  }

  export enum FormValidate {
    passwordMinLength = 8,
  }

  export enum Versions{
    versionErrorName = 'getting latest version details',
  }

  export enum HelpHero {
    helpHeroJigCountKey = 'jigCount',
    helpHeroModelsCountKey = 'modelsCount',
    helpHeroTenantUsersCountKey = 'tenantUsersCount',
    helpHeroTenantLogoUrlKey = 'tenantLogoUrl',
  }

  export enum SharePermission {
    teamPermissionView = 'view',
    teamPermissionReshare = 'sharable-readonly',
  }

  export enum Subscription {
    subscriptionTierFree = 'free',
    subscriptionTierTeam = 'team',
    subscriptionTierOrganization = 'organization',
    ManagedByTypeTeamManager = 'team_manager',
    ManagedByTypeJigStaff = 'jig_staff',
    pricingSet_2020_01 = 'set_2020_01',
    pricingSet_2022_07 = 'set_2022_07',
    pricingSet_2023_02 = 'set_2023_02',
    pricingSet_2023_06 = 'set_2023_06',
    defaultSeatsIncludedInTier = '-',
    defaultMaxJigsInTier = 0,
    defaultMaxJigsPerInTier = '-',
    embedQueryParamDefaultLang = 'en-US',
  }

  export const subscriptionTiers: Subscription[] = [
    Subscription.subscriptionTierFree,
    Subscription.subscriptionTierTeam,
    Subscription.subscriptionTierOrganization
  ]

  export const subscriptionManagedByTypes: {
    value: Subscription,
    text: string
  }[] = [
    {
      value: Subscription.ManagedByTypeTeamManager,
      text: 'Team manager',
    },
    {
      value: Subscription.ManagedByTypeJigStaff,
      text: 'Jig staff',
    }
  ]

  export const pricingSets: Subscription[] = [
    Subscription.pricingSet_2020_01,
    Subscription.pricingSet_2022_07,
    Subscription.pricingSet_2023_02,
    Subscription.pricingSet_2023_06
  ]

  export enum Tracking {
    eventLabel = 'target-properties'
  }

  export enum AvatarColor {
    dune_100 = 'dune-100',
    rain_700 = 'rain-700',
    info_500 = 'info-500',
    info_300 = 'info-300'
  }

  export const userAvatarColors = [AvatarColor.dune_100, AvatarColor.rain_700, AvatarColor.info_500, AvatarColor.info_300]

  export enum AppVariant {
    MacOS = 'macos',
    Windows = 'win',
    Windows_Zip = 'win-zip'
  }

  export enum AppDownloadURL {
    dev = 'https://link.jig.space/e/integration-test-dummy-link'
  }

  export const appDownloadDeeplinks = {
    jigspace: [
      {
        platform: VersionConstants.PlatformsOptions.desktop,
        variant: AppVariant.MacOS,
        nonProdDeeplinkUrl: AppDownloadURL.dev,
      },
      {
        platform: VersionConstants.PlatformsOptions.desktop,
        variant: AppVariant.Windows,
        nonProdDeeplinkUrl: AppDownloadURL.dev,
      },
      {
        platform: VersionConstants.PlatformsOptions.desktop,
        variant: AppVariant.Windows_Zip,
        nonProdDeeplinkUrl: AppDownloadURL.dev,
      },
    ],
  }

  export enum JigVisibilities {
    Public = 'public',
    Link = 'link',
    Private = 'private',
    Password = 'link-password',
  }

  export const jigVisibilities = {
    [JigVisibilities.Public]: 'Public sharing',
    [JigVisibilities.Link]: 'Anyone with link can view',
    [JigVisibilities.Private]: 'Private (Invite only)',
    [JigVisibilities.Password]: 'Password protected',
  }

  export enum JigCopyable {
    Owner = 'owner_user',
    Anyone = 'anyone',
    Tenant = 'owner_tenant',
  }

  export const copyableByList = [
    { value: JigCopyable.Anyone, copy: 'Anyone can copy', disabled: false },
    { value: JigCopyable.Tenant, copy: 'Team only', disabled: true },
  ]

  export const defaultTenantsPagination = {
    page: 1,
    limit: 10,
    name: '',
  }

  export const nonSystemErrors = {
    conflict: {
      status: 409,
      copy: '',
    },
    unprocessableEntity: {
      status: 422,
      copy: `Oops, the Jig has been updated by another more recent session and your changes cannot be saved. Refresh to see the latest version.`,
    }
  }

  // Match all non-printable characters but except '\n', '\r', '\t', '\b', '\f' characters.
  export const nonPrintableCopyRegex = new RegExp(/(?:(?![\n\r\t\b\f])[\p{Cc}]+|\\u([0-9a-fA-F]{4}))/gu)

  export enum Features {
    AVPFullAccessFeatureGate = 'ViewingGate_MAR_2024',
    AVPFeaturePullApartKey = 'AVP_Feature_PullApart',
  }

  export enum HTTPAction {
    GET,
    POST,
    PUT,
    DELETE,
  }

  export enum PermissionsResourceType {
    Jig = 0,
  }

  export enum PermissionsAudienceType {
    User = 0,
  }

  export enum Report {
    MostPopularJig = 'MostPopularJigs',
    JigViewActivity = 'JigViewActivity',
    AppActivities = 'AppActivities',
    TenantDashboard = 'TenantDashboard',
  }

  export enum ReportActivity {
    ViewingJig = 'ViewingJig',
    ActionTrigger = 'ActionTriggered',
  }
}
