import { getInstance } from '@/plugins/auth0'
import { BillingSource } from '@/modules/subscription/types'
import { AppConst } from '@/store/modules/constants'
import {
  Tenant,
  TenantLogoImageResponse,
  TenantToken,
  TenantUser,
} from '@/store/modules/app/types'
import { PermissionsMap } from '@/store/types'
import { ListHelpers } from '@/utils/list-helpers'
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export type ColorKey = 'Color1' | 'Color2'
export type FnOnTenantLogoImageDownloaded = (response: TenantLogoImageResponse) => any

let authService: any

export class TenantHelpers {
  public static readonly InvalidTenantID: number = -1
  public static readonly InvalidTenantIndex: number = -1
  public static readonly InvalidTenantUserCount: number = -1
  public static readonly ExtraPermissionNone = ''
  public static readonly ExtraPermissionManager = 'Team Manager'
  public static readonly ExtraPermissionBillingContact = 'Billing Contact'
  public static readonly ExtraPermissionDisplayNames: string[] = [
    TenantHelpers.ExtraPermissionNone,
    TenantHelpers.ExtraPermissionManager,
    TenantHelpers.ExtraPermissionBillingContact,
  ]
  public static readonly TenantDefaultColor1: string = '304DE4'
  public static readonly TenantDefaultColor2: string = '001D45'
  public static readonly TenantDefaultColor1Hex: string = '#' + TenantHelpers.TenantDefaultColor1 + 'FF'
  public static readonly TenantDefaultColor2Hex: string = '#' + TenantHelpers.TenantDefaultColor2 + 'FF'

  public static GetDefaultTenant() {
    return <Tenant> {
      ID: this.InvalidTenantID,
      Active: false,
      Name: '',
      CreatedAt: new Date(),
      UpdatedAt: new Date(),
      UserCount: 0,
      ColorHex1: this.TenantDefaultColor1,
      ColorHex2: this.TenantDefaultColor2,
      Logourl: '',
      Subscription: {
        PricingSet: AppConst.Subscription.pricingSet_2023_06
      },
      // Deduced client side
      LogoImage: '',
      JigFinishedCustomLink: '',
      LogoUpdated: false,
    }
  }

  public static GetDefaultTenantToken() {
    return <TenantToken> {
      id: this.InvalidTenantID,
      rol: [],
      paid_up: false,
      permissions: {} as PermissionsMap,
      billing_source: 'none',
      billing_status: '',
      // Deduced client side
      name: '',
      extraPermissionNames: [],
      tenant_type: '',
    }
  }

  public static GetJigSpaceStaffTenantID(): number {
    return this.jigSpaceStaffTenantID
  }

  public static TrySetJigSpaceStaffTenantID(tokens: TenantToken[]): boolean {
    const t = ListHelpers.getItemById(tokens, 'name', TenantHelpers.jigSpaceStaffTenantName)
    if (t !== null && t !== undefined) {
      this.jigSpaceStaffTenantID = (t as TenantToken).id
      return true
    }
    return false
  }

  public static IsSuperUser(tokens: TenantToken[]): boolean {
    for (const token of tokens) {
      if (token.rol && token.rol.indexOf(TenantHelpers.roleSuperUser1) !== -1) {
        return true
      }
    }
    return false
  }

  public static GetTenantTokenByTenantID(tokens: TenantToken[], tenantId: number): TenantToken | undefined {
    const index = ListHelpers.getIndexById(tokens, 'id', tenantId)

    if (index !== TenantHelpers.InvalidTenantIndex) {
      return tokens[index]
    }
    return undefined
  }

  public static HasJigSpaceStaffToken(tokens: TenantToken[]): boolean {
    for (const tenants of tokens) {
      if (tenants.id === TenantHelpers.jigSpaceStaffTenantID && !TenantHelpers.IsGuestUser(tenants)) {
        return true
      }
    }

    return false
  }

  public static IsGuestUser(tenant: TenantToken): boolean {
    return tenant.rol && tenant.rol.includes(TenantHelpers.RoleGuestViewer1)
  }

  public static IsTenantJigSpaceStaff(tenant: Tenant): boolean {
    if (tenant !== undefined) {
      return tenant.ID === TenantHelpers.GetJigSpaceStaffTenantID()
    }
    return false
  }

  public static IsChangeOwnerTeamAllowed(tenantUser: TenantUser | undefined | null, myTenantID: number, ownerTenantID?: number): boolean {
    const authService = TenantHelpers.getAuthService()
    if (TenantHelpers.IsSuperUser(authService.tenants)) {
      return true
    }

    return myTenantID === ownerTenantID && tenantUser != null && tenantUser.extraPermissionNames.includes(TenantHelpers.ExtraPermissionManager)
  }

  public static IsSuperUserOrJigStaff(): boolean {
    const authService = TenantHelpers.getAuthService()
    return TenantHelpers.IsSuperUser(authService.tenants) ||
      TenantHelpers.HasJigSpaceStaffToken(authService.tenants)
  }

  public static CanAddBillableUsers(tenantToken: TenantToken) {
    // In-app Subscriptions (through a mobile store) can't be edited by our API off-device, so we can't let these sources add billable users and we encourage them to change to stripe instead.
    return tenantToken.billing_source != BillingSource.Apple
  }

  public static GetTenantByIndex(tenantIndex: number, tenants: Tenant[]): Tenant | undefined {
    if (tenantIndex >= 0 && tenants.length > tenantIndex) {
      return tenants[tenantIndex]
    }
    return undefined
  }

  public static GetTenantByID(tenantID: number, tenants: Tenant[]): Tenant | undefined {
    return ListHelpers.getItemById(tenants, 'ID', tenantID)
  }

  public static GetTenantID(tenantIndex: number, tenants: Tenant[]): number {
    const tenant = TenantHelpers.GetTenantByIndex(tenantIndex, tenants)
    if (tenant === undefined) {
      return TenantHelpers.InvalidTenantID
    }
    return tenant.ID
  }

  public static SetTenantDefaultLogoIfNull(tenant: Tenant) {
    if (tenant.LogoImage === undefined || tenant.LogoImage === null || tenant.LogoImage.length === 0) {
      tenant.LogoImage = TenantHelpers.defaultLogoImagePath
    }
  }

  public static HasDefaultLogoImage(tenant: Tenant): boolean {
    return (tenant.LogoImage !== undefined && tenant.LogoImage !== null &&
      tenant.LogoImage === TenantHelpers.defaultLogoImagePath)
  }

  public static LoadLogos(tenants: Tenant[], onFinish: FnOnTenantLogoImageDownloaded) {
    if (tenants === undefined || onFinish === null) {
      return
    }

    for (let i = 0; i < tenants.length; i++) {
      TenantHelpers.LoadLogoByIndex(i, tenants, onFinish)
    }
  }

  // LoadLogo - loads the logo for the given tenant index based on the supplied array if the logo data isn't already present.
  // If the request is successful the onFinish callback is called. The function
  // returns true if the request was sent.
  public static LoadLogoByIndex(tenantIndex: number, tenants: Tenant[], onFinish: FnOnTenantLogoImageDownloaded): boolean {
    if (tenantIndex !== TenantHelpers.InvalidTenantIndex) {
      const t = TenantHelpers.GetTenantByIndex(tenantIndex, tenants)
      if (t === undefined || onFinish === null) {
        return false
      }
      return TenantHelpers.LoadLogoFromTenant(t, onFinish)
    }
    return false
  }

  public static LoadLogoFromTenant(t: Tenant, onFinish: FnOnTenantLogoImageDownloaded): boolean {
    const authService = TenantHelpers.getAuthService()
    if (t.Logourl.length > 0 && (TenantHelpers.HasDefaultLogoImage(t) || t.LogoUpdated)) {
      // Fetch logo image
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${authService.accessToken}`,
          [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: t.ID,
        },
      }
      Axios.get(`${AppConst.apiDomain}${AppConst.tenantsImagesV1Url}`, config).then(
        (response: AxiosResponse) => {
          if (response.status == 200) {
            onFinish({ ID: t.ID, ImageData: response.data } as TenantLogoImageResponse)
          }
        }
      )

      if (t.LogoUpdated) {
        t.LogoUpdated = false
      }
      return true
    }
    return false
  }

  private static getAuthService() {
    if (!authService) {
      authService = getInstance()
    }

    return authService
  }

  public static jigSpaceStaffTenantName = 'JigSpaceStaff'
  private static jigSpaceStaffTenantID = TenantHelpers.InvalidTenantIndex
  private static roleSuperUser1 = 'role_superuser1'
  public static roleCreator1 = 'role_creator1'
  public static rolePresenter1 = 'role_presenter1'
  public static RoleGuestViewer1 = 'role_guestviewer1'
  public static defaultLogoImagePath = './img/Logo_JigSpace.svg'
}
