import library from '@/modules/library/library'
import { getInstance } from '@/plugins/auth0'

// Store functionality
import actions from '@/store/actions'
import getters from '@/store/getters'
import modules from '@/store/modules'
import { Tenant } from '@/store/modules/app/types'
import mutations from '@/store/mutations'
import state from '@/store/state'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { HelpHero } from 'helphero'

/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */
// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

declare var consoleLog : any

Vue.use(Vuex)

// devtools true set before the new Vuex.Store allows the vue extension to inspect the state in Chrome.
Vue.config.devtools = process.env.VUE_APP_DEV_TOOLS === 'true'

modules[library.moduleName] = library

// store function that can be shared among store modules.
const storePlugins = (store: any) => {
  store.getMyTenantID = (state: any): number => {
    if (state.myTenantIndex !== TenantHelpers.InvalidTenantIndex) {
      const myTenant = state.myTenants[state.myTenantIndex] as Tenant
      if (myTenant !== null && myTenant.ID > 0) {
        return myTenant.ID
      }
    }

    return TenantHelpers.InvalidTenantID;
  }

  store.updateHelpHeroData = (key: string, value: any) => {
    if (!Vue.prototype.$hlp) {
      consoleLog('!HelpHero is not ready')
      return
    }
    const data = {} as any;
    data[key] = value;
    (Vue.prototype.$hlp as HelpHero).update(data)
    consoleLog(`Updated HelpHero with data: ${JSON.stringify(data)}`)
  }

  store.resetLibraryData = () => {
    store.commit('teamlibrary/resetLibraryData', null, {
      root: true
    })
  }

  store.authService = () => getInstance()
}

const store = new Vuex.Store({
  actions,
  getters,
  mutations,
  state,
  modules,
  plugins: [storePlugins],
})

export default store
