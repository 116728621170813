
import { Tenant, TenantToken } from '@/store/modules/app/types'
import { TenantHelpers } from '@/utils/tenant-helpers'

const invalidNumber = -1

export default {
  uid: 0,
  forceRerenderKey: 0,
  queuedStatusResult: {
    queueId: '', // UUID
    processingType: '', // 'undefined', 'astc_library_entry_thumbnail', 'obj_assimp' etc
    status: '', // 'queued', 'in-progress', 'done', 'failed'
    originalFile: '',
    files: [],
    params: [], // map of string:string
    errorMessage: '',
    wasRead: false,
    dateCreated: '', // golang time.Time
  },
  creditCardSetupInnanntClientSecret: '',
  customerWithPaymentMethod: {},
  // This is a trimmed down example of a subscription from Stripe
  subscription: {
    "stripeSubscription": {
      "id": "sub_HBQjK9ylcGKmDm",
      "object": "subscription",
      "billing_cycle_anchor": 1588120445,
      "cancel_at_period_end": false,
      "canceled_at": 1588203315,
      "collection_method": "charge_automatically",
      "created": 1588120445,
      "current_period_end": 1590712445,
      "current_period_start": 1588120445,
      "customer": "cus_HBQaFPI1kIZP1d",
      "default_payment_method": null,
      "default_source": null,
      "ended_at": 1588203315,
      "latest_invoice": {
        "id": "in_1Gd3sHIw3NbX0D1sti8M7yZI",
        "hosted_invoice_url": "https://pay.stripe.com/invoice/acct_19knSxIw3NbX0D1s/invst_HBQjf215NW6iPKYkiu3Cn6nDZBXJYHh",
        "invoice_pdf": "https://pay.stripe.com/invoice/acct_19knSxIw3NbX0D1s/invst_HBQjf215NW6iPKYkiu3Cn6nDZBXJYHh/pdf",
        "paid": false,
        "payment_intent": {
          "id": "pi_1Gd3sHIw3NbX0D1sqlIROrPg",
          "status": "canceled",
        },
        "period_end": 1588120445,
        "period_start": 1588120445,
        "post_payment_credit_notes_amount": 0,
        "pre_payment_credit_notes_amount": 0,
        "starting_balance": 0,
        "status": "void",
        "status_transitions": {
          "finalized_at": 1588120445,
          "voided_at": 1588203315,
        },
        "subscription": "sub_HBQjK9ylcGKmDm",
        "subscription_proration_date": 0,
        "subtotal": 2000,
        "total_tax_amounts": [],
        "total": 2000,
        "webhooks_delivered_at": 1588120445,
      },
      "metadata": {
        "Environment": "dev",
        "TenantId": "1490",
      },
      "status": "incomplete_expired",
    },
  },
  checkoutUrl: '',
  customerPortalUrl: '',
  dynaconfConfig: {
    pricing: {
      sets: {},
    },
  },
  // deprecated, use myTenant
  myPreviousTenantIndex: TenantHelpers.InvalidTenantIndex,
  // deprecated, use myTenant
  myTenantIndex: TenantHelpers.InvalidTenantIndex,
  myTenant: TenantHelpers.GetDefaultTenant(),
  myTenantToken: TenantHelpers.GetDefaultTenantToken(),
  myTenantTokens: [] as TenantToken[],
  myTenants: [] as Tenant[],
  myTenantTotalJigCount: invalidNumber,
  tenants: [],
  tenantUsers: [],
  tenantUserCount: invalidNumber,
  tenantUserRoles: [],
  tenantUserRoleDisplayNames: [],
  tenantSetupJob: {},
  totalTenantsQty: invalidNumber,
  totalTenantsPages: invalidNumber,
}
