import { AppConst } from '@/store/modules/constants'

import {
  Color,
  NullableTime,
  TargetApp,
} from '@/store/types'

export interface CRUD {
  C?: boolean
  R?: boolean
  D?: boolean
  U?: boolean
}

export interface Permission {
  ID: number
  CRUD: CRUD
  ResID: number
  ResType: AppConst.PermissionsResourceType
  AudID: number
  AudName: string
  AudType: AppConst.PermissionsAudienceType
  ExpiresAt: NullableTime
}

export interface JigModelData {
  ModelName: string
  GeometryString: string
  HasTexture: boolean
  TextureString: string
  Materials: Material[]
  Meshes: Mesh[]
  Models: Model[]
  Textures: any[]
}

export interface Material {
  UID: string
  Id: number
  Name: string
  EmissiveColor: Color
}

export interface Mesh {
  UID: string
  Name: string
  MaterialId: number
  MaterialUID: string
  TextureUID: string
}

export interface Model {
  UID: string
  OriginalUID: string
  Uri: string
  FullFileName: string
  GeometryString: string
  HasTexture: boolean
  IsFlipped: boolean
  SubMeshes: Mesh[]
  Textures: any[]
  GeometryCanBeIgnored: boolean
}

export interface JigSceneData {
  BackgroundImage: string
  BackgroundColor: Color
}

export interface JigStageData {
  Stages: Stage[]
}

export interface Stage {
  StageInstructions: string
  StagePosition: StagePosition
  StageSound: Sound
  StageRotation: StagePosition
  StageScale: StagePosition
  Loop: boolean
  IsViewpointActiveInStage: boolean
  ViewpointDistance: number
  ViewpointPosition: StagePosition
  ViewpointRotation: StagePosition
  ViewpointTargetPosition: StagePosition
  SerializedElements: SerializedElement[]
}

export interface SerializedElement {
  ElementIndex: number
  ElementUniqueName: string
  ModelUID: string
  ElementData: ElementData
}

export interface ElementData {
  ObjectName: string
  Label: string
  LabelOnForThisStage: boolean
  ShowcaseOnThisStage: boolean
  MaterialUID: string
  TextureUID: string
  ParentName: string
  ParentObject: ParentObject
  ParentModelUID: string
  Color: Color
  EmissiveColor: Color
  Metallic: number
  Smoothness: number
  Normal: number
  Height: number
  Occlusion: number
  Sound: Sound
  Position: StagePosition
  Rotation: StagePosition
  Scale: StagePosition
  AutoSpinAxis: StagePosition
  AutoSpinSpeed: number
  AutoSpinOnThisStage: boolean
  Actions: any[]
}

export interface StagePosition {
  x: number
  y: number
  z: number
  w?: number
}

export interface ParentObject {
  instanceID: number
}

export interface Sound {
  Uri: string
  Delay: number
  Loop: boolean
}

export interface TenantJigPermissions {
  TenantID: number
  Permissions: string[]
  Type?: string
}

export interface FeatureOption {
  enabled: boolean
  settingType: string
}

export interface JigFeatureOption {
  [AppConst.Features.AVPFeaturePullApartKey]: FeatureOption
}

export interface JigFeatures {
  options: JigFeatureOption
}

export interface JigMetaData {
  Author: string
  Categories: string[]

  CopyableBy: string

  DateCreated: string
  DatePublished: string
  DateUpdated: string
  DeeplinkURL?: string
  DeeplinkURLRequesting?: boolean

  FormatVersion: number
  FormatVersionAtCreation: number
  GlobalScale?: number
  Id: string
  MovoEnabled?: boolean
  ProjectDescription: string
  ProjectName: string
  ProjectVisibility: string
  RequestUserPermission: Permission
  OwnerTenantID?: number
  Password?: string
  PasswordDateCreated?: string
  Tags: string[]
  Tenants?: TenantJigPermissions[]
  ThumbnailURL: string
  Type: string
  Uid: string
  Version: number
  Views: number
  votes: number
  Features: JigFeatures
}

export interface JigShareRequest {
  jig: MyJig | JigMetaData
  branchIOCustomData: any
  targetApp: TargetApp
  route: string
}

export interface JigMetrics {
  views: number
  avgViewingTimeSeconds: number
  avgViewingTime: string
  totalViewingTimeSeconds: number
  totalViewingTime: string
  uniqueUsersPresentingJig: number
}

// MyJig is the data returned from action 'loadMyJigs' and it has different naming to JigMetaData
// even though it is essentially almost the same data.
export interface MyJig {
  author: string
  categories: string[]
  data: any
  dateCreated: string
  datePublished: string
  dateUpdated: string
  deeplinkURL?: string
  deeplinkURLRequesting: boolean
  description: string
  formatVersion: number
  globalScale?: number
  id: string
  metrics: JigMetrics
  movoEnabled?: boolean
  name: string
  ownerTenantID?: number
  password?: string
  passwordDateCreated?: string
  requestUserPermission: Permission
  tags: string[]
  thumbnailURL: string
  type: string
  uid: string
  version: number
  versionParts: string
  views: number
  visibility: string
  voted: boolean
  votes: number
}

// TeamJig follows the camel case format of MyJig, it contains the combination of MyJig types and JigMetaData types.
// The reason for this type is that JigsListing.vue is shared between myJigs and TeamJigs functionalities. One returning V1 jig data and one returning V2 jig data.
// It's easier to merge to 1 data structure so component is easier to handle both data types.
export interface TeamJig {
  author: string
  categories: string[]
  data?: any,
  dateCreated: string
  datePublished: string
  dateUpdated: string
  deeplinkURL?: string
  deeplinkURLRequesting?: boolean
  description: string
  formatVersion: number
  globalScale?: number
  id: string
  metrics?: JigMetrics
  movoEnabled?: boolean
  name: string
  ownerTenantID?: number
  password?: string
  passwordDateCreated?: string
  requestUserPermission: Permission
  tags: string[]
  Tenants?: TenantJigPermissions[]
  thumbnailURL: string
  type: string
  uid: string
  version: number
  versionParts?: string
  views: number
  visibility?: string
  votes: number
}

export interface JigPermissionCreateRequest {
  UrlParams: {
    email: string
    type: string
  }
  JigId: string
}

export interface JigPermissionDeleteRequest {
  ResID: number
  PermID: number
}

export interface JigPermissionUpdateRequest {
  JigId: string
  Perm: Permission
}

export interface CopyJigResult {
  id: string
  name: string
}

export interface SaveJigResult {
  message: string
  status: string
  version: string
}

export interface SaveJigVisibilityResult {
  status: string
  visibility: string
  Password: string
  PasswordDateCreated: string
}

export interface JigCategoryOption {
  text: string
  value: string
}

export interface ChangeJigOwnerRequest {
  JigId: string
  DesiredOwnerEmail: string
  DesiredOwnerTenantID?: number
  action?: string
}

export interface ChangeOwnerTenantRequest {
  JigId: string
  DesiredOwnerTenantID: number
}

export interface ChangeJigOwnerResult {
  message: string
  status: string
}

export interface JigVisibilities {
  jigId: string
  visibility: string
  regenPassword: boolean
  version: number
}

export interface JigShareURLData {
  jigId: string
  requestInFlight: boolean
  shareURL: string
}

export interface SharedJigItem {
  id: string
  name: string
  author: string
  thumbnail: string
  deeplinkUrl: string
  visibility: string
  isOwner: boolean
  teamShared: boolean
  isStaffOwned: boolean
}
