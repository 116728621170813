import { AppConst } from '@/store/modules/constants'
import {
  ChangeJigOwnerResult,
  CopyJigResult,
  JigMetaData,
  JigShareURLData,
  MyJig,
  Permission,
  SaveJigResult,
  SaveJigVisibilityResult,
} from '@/store/modules/jig/types'
import {
  EmbedQueryParamConfig,
  JigSummaryStats,
  TenantDashboardReport,
  UploadThumbnailResult
} from '@/store/types'
import { ListHelpers } from '@/utils/list-helpers'

export default {
  changeIsStatisticsLoadingState(state: any, loading: boolean) {
    state.isStatisticsLoading = loading
  },
  updateMostPopularJigGraph(state: any, reportData: JigSummaryStats[]) {
    state.jigDailyActivityData = reportData
  },
  updateJigViewGraph(state: any, reportData: TenantDashboardReport) {
    state.jigDailyViewsData = reportData.Data.JigSummaryStats
  },
  updateMyJigs(state: any, myJigs: MyJig[]) {
    state.myJigs = myJigs
    this.updateHelpHeroData(AppConst.HelpHero.helpHeroJigCountKey, myJigs.length)
  },
  deleteFromMyJigsById(state: any, id: string) {
    ListHelpers.deleteById(state.myJigs, 'id', id)
  },
  setRequestingJigShareLink(state: any, changeData: JigShareURLData) {
    const myJigExist = state.myJigs.find((jig: MyJig) => jig.id === changeData.jigId)
    const jigMetadataExist = state.jigMetadata.Id === changeData.jigId

    if (!myJigExist && !jigMetadataExist) {
      return
    }

    if (jigMetadataExist) {
      const newJigMetadata = state.jigMetadata

      newJigMetadata.DeeplinkURLRequesting = changeData.requestInFlight
      newJigMetadata.DeeplinkURL = changeData.shareURL

      // Reset root state to trigger store update so component watch will be triggered
      state.jigMetadata = {
        ...newJigMetadata,
      }

      // It's either JigDetail or MyJig updates so stop processing further if it's JigDetail updates.
      return
    }

    if (myJigExist) {
      const myJigs = state.myJigs.map((jig: MyJig) => {
        const newJig = jig

        if (jig.id === changeData.jigId) {
          newJig.deeplinkURLRequesting = changeData.requestInFlight
          newJig.deeplinkURL = changeData.shareURL
        }

        return newJig
      })

      // Reset root state to trigger store update so component watch will be triggered
      state.myJigs = myJigs
    }
  },
  updateTeamJigs(state: any, teamJigs: JigMetaData[]) {
    state.teamJigs = teamJigs
    this.updateHelpHeroData(AppConst.HelpHero.helpHeroJigCountKey, teamJigs.length)
  },
  updateJigMetadata(state: any, jigMetadata: any) {
    state.jigMetadata = jigMetadata
  },
  updateJigPermissions(state: any, jigPermissions: Permission[]) {
    state.jigDetailPermissions = jigPermissions
  },
  updateCopyJigResult(state: any, copyJigResult: CopyJigResult) {
    state.copyJigResult = copyJigResult
  },
  updateSaveJigResult(state: any, saveJigResult: SaveJigResult) {
    state.saveJigResult = saveJigResult
  },
  updateSaveJigVisibilityResult(state: any, saveJigVisibilityResult: SaveJigVisibilityResult) {
    state.saveJigVisibilityResult = saveJigVisibilityResult
  },
  updateChangeJigOwnerResult(state: any, changeOwnerResult: ChangeJigOwnerResult) {
    state.changeJigOwnerResult = changeOwnerResult
  },
  uploadThumbnailResult(state: any, uploadThumbnailResult: UploadThumbnailResult) {
    state.uploadThumbnailResult = uploadThumbnailResult
  },
  updateEmbedParamsConfig(state: any, payload: EmbedQueryParamConfig) {
    state.embedParamsConfig = payload
  },
}
