declare var consoleLog: any
export class ListHelpers {
  public static getIndexById(list: any, keyName: any, keyValue: any): number {
    return list.findIndex((x: any) => x[keyName] === keyValue)
  }

  public static getItemById(list: any, keyName: any, keyValue: any): any {
    const index = ListHelpers.getIndexById(list, keyName, keyValue)
    if (index < 0 || index >= list.length) {
      // tslint:disable-next-line:no-console
      consoleLog(
        'ListHelpers.getItemById got invalid index. Key: [' + keyName.toString() + ']. Value: [' + keyValue.toString() + ']')
      return null
    }

    return list[index]
  }

  public static deleteById(list: any, keyName: any, keyValue: any) {
    // Search the state jig cache for the matching jig id and returns it's array index
    const index = ListHelpers.getIndexById(list, keyName, keyValue)
    if (index < 0 || index >= list.length) {
      // tslint:disable-next-line:no-console
      consoleLog('ListHelpers.deleteById got invalid index')
      return
    }

    // We use array splice to remove the value so it triggers a reactive update
    // through vuex
    list.splice(index, 1)
  }

  // calculateNextID finds the next ID value for the given list. If the list is null -1 is returned.
  // If the list is empty the first ID will be 1.
  public static calculateNextID(list: any, keyName: any): number {
    if (keyName === undefined) {
      keyName = 'ID'
    }

    let nextId = -1
    if (list != null) {
      nextId = 1
      for (const t of list) {
        if (t[keyName] >= nextId) {
          nextId = t[keyName] + 1
        }
      }
    }
    return nextId
  }
}
