import { CreateCheckoutRequest, CreateCustomerPortalRequest } from '@/modules/subscription/types'
import { apiRequest, onApiCatchError } from '@/store/modules/ApiRequestHelper'
import { AppConst } from '@/store/modules/constants'
import { Tenant } from '@/store/modules/app/types'
import {
  DynaconfConfig,
  PricignListItem,
  PricingTier,
} from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import Axios, { AxiosRequestConfig } from 'axios'

import { apiRequestCustomConfig } from '@/store/modules/ApiRequestHelper'
import {
  GetTenantsByUserRequest,
  TenantCreateRequest,
  TenantLogoImageResponse,
  TenantToken,
  TenantUserAddRequest,
  TenantUserRemoveRequest,
  TenantUserUpdateRequest,
} from '@/store/modules/app/types'
import { UploadTenantLogoRequest } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'

declare var consoleLog: any
declare var consoleError: any

export default {
  setUid(context: any, uid: number) {
    context.commit('updateUid', uid)
  },

  createCheckout(context: any, payload: CreateCheckoutRequest) {
    const msg = `creating checkout for user ${payload.UrlParams.email} and tenant ${payload.UrlParams.tenantId}`
    if (payload.UrlParams.email.length === 0 ||
      payload.UrlParams.tenantId.length === 0 ||
      payload.UrlParams.successUrl.length === 0 ||
      payload.UrlParams.cancelUrl.length === 0) {
      return new Promise((resolve) => {
        onApiCatchError(context, Error(), `${msg}. Error: missing all parameters.`)
        resolve(false)
      })
    } else {
      return apiRequest({
        action: AppConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.createStripeCheckoutUrl}`,
        params: payload.UrlParams,
      },
      context,
      'updateCheckoutUrl',
      msg,
      AppConst.StoreHelper.loadingModalMutationName,
      {
        [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.UrlParams.tenantId,
      })
    }
  },

  createCustomerPortal(context: any, payload: CreateCustomerPortalRequest) {
    const myTenant = context.state.myTenants[context.state.myTenantIndex] as Tenant
    if (myTenant === null || myTenant.ID <= 0) {
      return Promise.reject('Invalid Tenant ID')
    }
    const msg = `creating customer portal for tenant ${myTenant.ID}`
    if (payload.UrlParams.returnUrl.length === 0) {
      return new Promise((resolve) => {
        onApiCatchError(context, Error(), `${msg}. Error: missing all parameters.`)
        resolve(false)
      })
    } else {
      return apiRequest({
        action: AppConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.createStripeCustomerPortalUrl}`,
        params: payload.UrlParams,
      },
      context,
      'updateCustomerPortalUrl',
      msg,
      AppConst.StoreHelper.loadingModalMutationName,
      {
        [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: myTenant.ID,
      })
    }
  },

  setBranchIOError(context: any, error: any) {
    context.commit(AppConst.StoreHelper.SetApiErrorMutationName, `Branch IO - something went wrong: ${error}.`, { root: true })
  },

  // unused in favor of updateCustomerPaymentMethod
  async getCreditCardSetupIntent(context: any, payload: any): Promise<boolean> {
    if (payload.length === 0) {
      return Promise.reject('invalid getCreditCardSetupIntent, payload must be the customerEmail')
    } else {
      return apiRequest(
        {
          action: AppConst.HTTPAction.POST,
          url: `${AppConst.APIEndpoint.billingUrl}/stripe/v1/setup-intents`,
          params: { customerEmail: payload },
        },
        context,
        'updateCreditCardSetupIntent',
        `getting setup intent`
      )
    }
  },

  async updateCustomerPaymentMethod(context: any, payload: any): Promise<boolean> {
    if (payload.customerEmail === undefined || payload.customerEmail.length === 0 ||
      payload.paymentMethodId === undefined || payload.paymentMethodId.length === 0) {
      return Promise.reject('invalid updateCustomerPaymentMethod, payload must have customerEmail and paymentMethodId')
    } else {
      return apiRequest(
        {
          action: AppConst.HTTPAction.POST,
          url: `${AppConst.APIEndpoint.billingUrl}/stripe/v1/customer-payment-method`,
          params: { customerEmail: payload.customerEmail, paymentMethodId: payload.paymentMethodId },
        },
        context,
        'updateCustomerPaymentMethod',
        `updating customer payment method`,
        '',
        '',
        payload.onApiError,
      )
    }
  },

  // getSubscriptionWithInvoice requires the user to have billing_contact permissions for that tenant
  async getSubscriptionWithInvoice(context: any, payload: any): Promise<boolean> {
    if (payload.customerEmail === undefined || payload.customerEmail.length === 0 || payload.tenantId === undefined) {
      return Promise.reject('invalid getSubscription, payload must have customerEmail and tenantId')
    } else {
      return apiRequest(
        {
          action: AppConst.HTTPAction.GET,
          url: `${AppConst.APIEndpoint.billingUrl}/stripe/v1/subscription-with-invoice`,
          params: { customerEmail: payload.customerEmail, tenantId: payload.tenantId },
        },
        context,
        'updateSubscription',
        `updating subscription`,
        '',
        {
          [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.tenantId,
        }
      )
    }
  },

  async getSubscription(context: any, payload: any): Promise<boolean> {
    if (payload.customerEmail === undefined || payload.customerEmail.length === 0 || payload.tenantId === undefined) {
      return Promise.reject('invalid getSubscription, payload must have customerEmail and tenantId')
    } else {
      return apiRequest(
        {
          action: AppConst.HTTPAction.GET,
          url: `${AppConst.APIEndpoint.billingUrl}/stripe/v1/subscription`,
          params: { customerEmail: payload.customerEmail, tenantId: payload.tenantId },
        },
        context,
        'updateSubscription',
        `updating subscription`,
      )
    }
  },

  // setCancelSubscriptionAtEnd requires the user to have billing_contact permissions for that tenant
  async setCancelSubscriptionAtEnd(context: any, payload: any): Promise<boolean> {
    if (payload.stripeSubscriptionId === undefined || payload.stripeSubscriptionId.length === 0 ||
      payload.changedBy === undefined || payload.changedBy.length === 0 || payload.isCancelAtPeriodEnd === undefined || payload.tenantId === undefined) {
      return Promise.reject('invalid setCancelSubscriptionAtEnd, payload must have stripeSubscriptionId and changedBy and isCancelAtPeriodEnd and tenantId')
    } else {
      return apiRequest(
        {
          action: AppConst.HTTPAction.PUT,
          url: `${AppConst.APIEndpoint.billingUrl}/stripe/v1/subscription-cancel-at-period-end`,
          body: { stripeSubscriptionId: payload.stripeSubscriptionId, changedBy: payload.changedBy, isCancelAtPeriodEnd: payload.isCancelAtPeriodEnd },
        },
        context,
        'updateSubscription',
        `updating subscription`,
        '',
        {
          [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.tenantId,
        }
      )
    }
  },

  forceRerenderCoreView(context: any, payload: any) {
    context.commit('setforceRerenderKey', payload)
  },

  async getDynaconfConfig(context: any): Promise<boolean> {
    const response: any = await Axios.get(`${AppConst.apiDomain}${AppConst.APIEndpoint.dynaconfConfig}`)

    if (response.data && response.data.pricing) {
      const pricingSets: { [key: string]: PricignListItem } = {}

      response.data.pricing.sets.forEach((set: any) => {
        pricingSets[set.name] = {
          name: set.name,
          tiers: {},
        }

        set.tiers.forEach((tier: PricingTier) => {
          pricingSets[set.name].tiers[tier.name] = tier
        })
      })

      context.commit('updateDynaconfConfig', {
        pricing: {
          sets: pricingSets,
        },
      } as DynaconfConfig)
      return true
    } else {
      consoleLog(`Dynoconf config retrieve failed, response was: ${JSON.stringify(response)}`)
      context.commit(AppConst.StoreHelper.SetApiErrorMutationName, `Something went wrong getting dynaconf config.`, { root: true })
      return false
    }
  },


  loadMyTenants(context: any, payload: GetTenantsByUserRequest): Promise<boolean> {
    const uid = this.authService().uid
    if (uid === null || uid <= 0) {
      return Promise.reject('invalid this.authService().uid')
    }
    return apiRequest({
      action: AppConst.HTTPAction.GET,
      url: `${AppConst.APIEndpoint.usersV1Url}/${uid}/tenants/v1`,
      params: {
        ...payload.UrlParams,
      },
    },
    context,
    'updateMyTenants',
    `loading my organisation`,
    payload.ModalLoad ? AppConst.StoreHelper.loadingModalMutationName : AppConst.StoreHelper.loadingMutationName)
  },

  // overwrites the myTenants array with the tenants array
  updateMyTenantsWithNewTenant(context: any, tenant: Tenant) {
    const existingTenatnIndex = context.state.myTenants.findIndex((t: Tenant) => t.ID === tenant.ID)

    if (existingTenatnIndex !== -1) {
      // Use JSON.stringify and parse to deep copy value of the tenant object with ability to handle nested object. Object.assign cannot handle nested objects/references.
      context.state.myTenants[existingTenatnIndex] = AppHelper.deepCopyNestedJsonSafeObject(tenant)

      return
    }

    context.state.myTenants.push(AppHelper.deepCopyNestedJsonSafeObject(tenant))
    context.commit('updateMyTenants', context.state.myTenants)
  },

  updateMyMemberTenants(context: any, memberTenants: TenantToken[]) {
    context.commit('updateMyMemberTenants', memberTenants)
  },

  setMyTenant(context: any, payload: any) {
    context.commit('setMyTenantIndexById', payload.TenantId)
  },

  setMyTenantIndex(context: any, tenantIndex: number) {
    context.commit('setMyTenantIndex', tenantIndex)
  },

  setMyTenantLogoByID(context: any, payload: TenantLogoImageResponse) {
    if (payload.ID === undefined) {
      return Promise.reject('Undefined TenantId')
    }
    if (payload.ID <= 0) {
      return Promise.reject('Invalid TenantId')
    }
    context.commit('updateMyTenantLogoByID', payload)
  },

  setMyPreviousTenantIndex(context: any, tenantIndex: number) {
    context.commit('setMyPreviousTenantIndex', tenantIndex)
  },

  loadMyTenant(context: any, payload: any): Promise<boolean> {
    if (payload.TenantId === undefined) {
      return Promise.reject('Undefined TenantId')
    }
    if (payload.TenantId <= 0) {
      return Promise.reject('Invalid TenantId')
    }
    const params: any = {}
    if (payload.clearCache) {
      params.timestamp = (new Date()).valueOf()
    }
    return apiRequest({
      action: AppConst.HTTPAction.GET,
      url: `${AppConst.APIEndpoint.tenantsV1Url}/${payload.TenantId}`,
      params,
    },
    context,
    'updateMyTenant',
    'loading my organisation',
    AppConst.StoreHelper.loadingMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
    })
  },

  loadMyTenantLogo(context: any, payload: Tenant): Promise<boolean> {
    if (context.state.myTenantIndex === TenantHelpers.InvalidTenantIndex) {
      return Promise.reject('Invalid Tenant Index')
    }
    const myTenant = context.state.myTenants[context.state.myTenantIndex] as Tenant
    if (myTenant === null || myTenant.ID <= 0) {
      return Promise.reject('Invalid Tenant ID')
    }
    return apiRequest({
      action: AppConst.HTTPAction.GET,
      url: `${AppConst.tenantsImagesV1Url}`,
    },
    context,
    'updateMyTenantLogo',
    'loading my organisation image',
    AppConst.StoreHelper.loadingMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: myTenant.ID,
    })
  },

  loadMyTenantUsers(context: any): Promise<boolean> {
    if (context.state.myTenantIndex === TenantHelpers.InvalidTenantIndex) {
      return Promise.reject('Invalid Tenant Index')
    }
    const myTenant = context.state.myTenants[context.state.myTenantIndex] as Tenant
    if (myTenant === null || myTenant.ID <= 0) {
      return Promise.reject('Invalid Tenant ID')
    }
    return apiRequest({
      action: AppConst.HTTPAction.GET,
      url: `${AppConst.APIEndpoint.tenantsV1Url}/users/v1`,
    },
    context,
    'updateMyTenantUsers',
    'loading my organisation users',
    AppConst.StoreHelper.loadingMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: myTenant.ID,
    })
  },

  loadTenantUserRoles(context: any, payload: any): Promise<boolean> {
    if (context.state.myTenantIndex === TenantHelpers.InvalidTenantIndex) {
      return Promise.reject('Invalid Tenant Index')
    }
    const myTenant = context.state.myTenants[context.state.myTenantIndex] as Tenant
    if (myTenant === null || myTenant.ID <= 0) {
      return Promise.reject('Invalid Tenant ID')
    }
    return apiRequest({
      action: AppConst.HTTPAction.GET,
      url: `${AppConst.APIEndpoint.tenantsV1Url}/roles/v1`,
    },
    context,
    'updateTenantUserRoles',
    'loading tenant user roles list',
    AppConst.StoreHelper.loadingMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: myTenant.ID,
    })
  },

  loadTenantTotalJigCount(context: any): Promise<boolean> {
    if (context.state.myTenantIndex === TenantHelpers.InvalidTenantIndex) {
      return Promise.reject('Invalid Tenant Index')
    }
    const myTenant = context.state.myTenants[context.state.myTenantIndex] as Tenant
    if (myTenant === null || myTenant.ID <= 0) {
      return Promise.reject('Invalid Tenant ID')
    }

    const requestUrl: string = `${AppConst.APIEndpoint.tenantsV1Url}/${myTenant.ID}${AppConst.APIEndpoint.tenantsJigsUrl}`

    const requestParams: any = {
      op: 'count',
      includeTenantPrivateJigs: true,
    }

    if (myTenant.Subscription.MaxJigsCountPer === 'user') {
      requestParams.filter = `uid,eq,${context.state.uid}`
    }

    return apiRequest({
      action: AppConst.HTTPAction.GET,
      url: requestUrl,
      params: requestParams,
    },
    context,
    'updateTenantTotalJigCount',
    'loading tenant total Jig count',
    AppConst.StoreHelper.loadingMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: myTenant.ID,
    })
  },

  setTenantLogo(context: any, payload: TenantLogoImageResponse) {
    if (payload.ID === undefined) {
      return Promise.reject('Undefined TenantId')
    }
    if (payload.ID <= 0) {
      return Promise.reject('Invalid TenantId')
    }
    context.commit('updateTenantLogo', payload)
  },

  async searchPaginatedTenants(context: any, payload?: any) {
    // context.commit('updateTenants', context.state.myTenants)
    const urlParams = payload ? {
      ...payload,
    } : {
      ...AppConst.defaultTenantsPagination,
    }

    if (payload && !payload.page) {
      urlParams.page = AppConst.defaultTenantsPagination.page
    }

    if (payload && !payload.limit) {
      urlParams.limit = AppConst.defaultTenantsPagination.limit
    }

    if (payload.name) {
      urlParams.name = payload.name
    } else {
      delete urlParams.name
    }

    if (payload.email) {
      urlParams.email = payload.email
    } else {
      delete urlParams.email
    }

    if (payload.sort) {
      urlParams.sort = payload.sort
    } else {
      delete urlParams.sort
    }

    return apiRequest({
      action: AppConst.HTTPAction.GET,
      url: `${AppConst.APIEndpoint.tenantsV1Url}`,
      params: urlParams,
    },
    context,
    'updateTenants',
    'loading tenants search results',
    AppConst.StoreHelper.loadingMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: context.state.myTenant.ID,
    })
  },

  loadTenantsVisibleToSuperUsers(context: any): Promise<boolean> {
    const uid = this.authService().uid
    if (uid === null || uid <= 0) {
      return Promise.reject('invalid this.authService().uid')
    }
    // Note: This request is only successfully callable by staff users and will return 401 if not staff
    return apiRequest({
      action: AppConst.HTTPAction.GET,
      url: `${AppConst.APIEndpoint.usersV1Url}/${uid}/tenants/v1`,
      params: { all: 'true' },
    }, context, 'updateTenants', `loading organisation`)
  },

  createTenant(context: any, payload: TenantCreateRequest): Promise<boolean> {
    return apiRequest({
      action: AppConst.HTTPAction.POST,
      url: `${AppConst.APIEndpoint.tenantsV1Url}`,
      body: payload,
    },
    context,
    '',
    `creating organisation`,
    AppConst.StoreHelper.loadingModalMutationName)
  },

  updateTenant(context: any, tenant: Tenant): Promise<boolean> {
    return apiRequest({
      action: AppConst.HTTPAction.PUT,
      url: `${AppConst.APIEndpoint.tenantsV1Url}`,
      body: tenant,
    },
    context,
    'updateTenant',
    `updating organisation`,
    AppConst.StoreHelper.loadingModalMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenant.ID,
    })
  },

  updateMyTenant(context: any, tenant: Tenant): Promise<boolean> {
    return apiRequest({
      action: AppConst.HTTPAction.PUT,
      url: `${AppConst.APIEndpoint.tenantsV1Url}`,
      body: tenant,
    },
    context,
    'updateMyTenant',
    `updating my organisation`,
    AppConst.StoreHelper.loadingModalMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenant.ID,
    })
  },

  loadTeamJigsCount(context: any) {
    const urlParams = {
      op: 'count',
    }
    const tenantId = this.getMyTenantID(context.state)

    return apiRequest(
      {
        action: AppConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.tenantsV1Url}/${tenantId}${AppConst.APIEndpoint.tenantsJigsUrl}`,
        params: urlParams,
      },
      context,
      'updateTenantUserCount',
      `getting TenantUserCount`,
      AppConst.StoreHelper.loadingMutationName,
      {
        [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },

  addTenantUser(context: any, payload: TenantUserAddRequest) {
    return apiRequest({
      action: AppConst.HTTPAction.POST,
      url: `${AppConst.APIEndpoint.tenantsV1Url}/users/v1`,
      params: payload.UrlParams,
    },
    context,
    '',
    `adding tenant user`,
    AppConst.StoreHelper.loadingModalMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
    })
  },

  updateTenantUser(context: any, payload: TenantUserUpdateRequest) {
    if (!payload.UrlParams.role) {
      return Promise.reject('User role is empty.')
    }

    return apiRequest({
      action: AppConst.HTTPAction.PUT,
      url: `${AppConst.APIEndpoint.tenantsV1Url}/users/v1/${payload.UrlParams.Uid}`,
      params: payload.UrlParams,
    },
    context,
    '',
    `updating tenant user`,
    AppConst.StoreHelper.loadingModalMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
    })
  },

  removeTenantUser(context: any, payload: TenantUserRemoveRequest) {
    return apiRequest({
      action: AppConst.HTTPAction.DELETE,
      url: `${AppConst.APIEndpoint.tenantsV1Url}/users/v1/${payload.Uid}`,
    },
    context,
    '',
    `removing user`,
    AppConst.StoreHelper.loadingMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
    })
  },

  async getTenantSetupJob(context: any, payload: any): Promise<boolean> {
    if (!payload || !payload.userEmail || payload.userEmail.length === 0) {
      return Promise.reject('invalid getTenantSetupJob, payload must be the userEmail')
    } else {
      return apiRequest(
        {
          action: AppConst.HTTPAction.GET,
          url: `${AppConst.APIEndpoint.tenantSetupJobsUrl}`,
          params: { userEmail: payload.userEmail },
        },
        context,
        'updateTenantSetupJob',
        `getting Tenant Setup Job`,
        '',
        '',
        payload.onApiError,
      )
    }
  },

  async createTenantSetupJob(context: any, payload: any): Promise<boolean> {
    if (!payload.job || payload.job.customerEmail.length === 0 || payload.job.tenantToCreate === undefined || payload.job.tenantToCreate.Name.length === 0) {
      return Promise.reject('invalid TenantSetupJob. Needs a CustomerEmail, Tenant Name. A CustomerPassword is required if making a new user')
    } else {
      return apiRequest(
        {
          action: AppConst.HTTPAction.POST,
          url: `${AppConst.APIEndpoint.tenantSetupJobsUrl}`,
          body: payload.job,
        },
        context,
        'updateTenantSetupJob',
        `creating Tenant Setup Job`,
        '',
        '',
        payload.onApiError,
      )
    }
  },

  async completeTenantSetupJob(context: any, payload: any): Promise<boolean> {
    return apiRequest(
      {
        action: AppConst.HTTPAction.PUT,
        url: `${AppConst.APIEndpoint.completedTenantSetupJobsUrl}`,
      },
      context,
      'updateTenantSetupJob',
      `completing Tenant Setup Job`
    )
  },

  uploadTenantLogoImage(context: any, payload: UploadTenantLogoRequest): Promise<boolean> {
    if (payload == null || payload.TenantId === TenantHelpers.InvalidTenantID || payload.Image.size === 0) {
      consoleError(`Invalid uploadTenantLogoImage payload. Required payload:
        UploadTenantLogoRequest {
          TenantId: number
          Image: File
        }
      Received payload:`, payload)
      return new Promise(() => false)
    } else {
      const file = new Blob([payload.Image], {
        type: 'application/octet-stream',
      })
      const formData = new FormData()
      formData.append('image', file)
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${this.authService().accessToken}`,
          [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
        },
        timeout: 60000, // 1 min as milliseconds
        baseURL: `${AppConst.apiDomain}`,
      }
      const request = {
        action: AppConst.HTTPAction.POST,
        url: `${AppConst.tenantsImagesV1Url}`,
        body: formData,
      }
      const requestPromise = apiRequestCustomConfig(
        request,
        context,
        '',
        `uploading new Tenant logo image. Tenant ID: ${payload.TenantId}, file: ${payload.Image.name}`,
        config,
        AppConst.StoreHelper.loadingModalMutationName)
      return requestPromise
    }
  },

  deleteTenantById(context: any, tenantId: number): Promise<boolean> {
    return apiRequest({
      action: AppConst.HTTPAction.DELETE,
      url: `${AppConst.APIEndpoint.tenantsV1Url}`,
    },
    context,
    'teamlibrary/deleteFromModelsLibraryById',
    `deleting organisation, id: ${tenantId}`,
    AppConst.StoreHelper.loadingMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
    })
  },
}
