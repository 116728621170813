import { getInstance } from '@/plugins/auth0'
import { AppConst } from '@/store/modules/constants'
import { HTTPRequest } from '@/store/types'
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
declare var consoleLog: any

export async function apiRequest(
  request: HTTPRequest,
  context: any,
  mutation: string,
  errorMsg: string,
  loadingStateMutationName: string = AppConst.StoreHelper.loadingMutationName,
  customHeaders: any = {},
  customErrorHandler: any = undefined): Promise<boolean> {
  const config: AxiosRequestConfig = {
    params: request.params,
    baseURL: AppConst.apiDomain,
  }
  const authService = getInstance()

  if (request.cancelToken) {
    config.cancelToken = request.cancelToken
  }

  if (request.baseURL) {
    config.baseURL = request.baseURL
  }

  if (authService.accessToken) {
    config.headers = {
      Authorization: `Bearer ${authService.accessToken}`,
    }
  }

  if (customHeaders != null && config.headers != null) {
    for (const key of Object.keys(customHeaders)) {
      config.headers[key] = customHeaders[key]
    }
  }
  return apiRequestCustomConfig(request, context, mutation, errorMsg, config, loadingStateMutationName, customErrorHandler)
}

export async function apiRequestCustomConfig(
  request: HTTPRequest,
  context: any,
  mutation: string,
  errorMsg: string,
  config: AxiosRequestConfig,
  loadingStateMutationName: string = AppConst.StoreHelper.loadingMutationName,
  customErrorHandler: any = undefined): Promise<boolean> {
  if (loadingStateMutationName !== '') {
    context.commit(loadingStateMutationName, true, { root: true })
  }
  let response!: AxiosResponse<any>
  let mutationData: any = {}

  try {
    if (request.action === AppConst.HTTPAction.GET) {
      response = await Axios.get(request.url, config)
      mutationData = response.data
    } else if (request.action === AppConst.HTTPAction.POST) {
      response = await Axios.post(request.url, request.body, config)
      mutationData = response.data
    } else if (request.action === AppConst.HTTPAction.PUT) {
      response = await Axios.put(request.url, request.body, config)
      mutationData = response.data
    } else if (request.action === AppConst.HTTPAction.DELETE) {
      response = await Axios.delete(request.url, config)
      // The deleted id element is always the last part of the path
      const pathname = config.baseURL ? new URL(`${config.baseURL}${request.url}`).pathname : new URL(request.url).pathname
      mutationData = pathname.split('/').pop()
    } else {
      throw new RangeError(`The request.action ${request.action} is not supported.`)
    }
  } catch (error) {
    if (customErrorHandler) {
      customErrorHandler(context, error, errorMsg, onApiCatchError)
    } else if (Axios.isCancel(error)) {
      consoleLog('Request is cancelled', error)
    } else {
      onApiCatchError(context, error, errorMsg)
    }
    return false
  }

  let returnResult = true

  if (mutation.length > 0) {
    context.commit(mutation, mutationData)
  } else {
    returnResult = mutationData
  }
  if (loadingStateMutationName !== '') {
    context.commit(loadingStateMutationName, false, { root: true })
  }
  context.commit(AppConst.StoreHelper.SetApiErrorMutationName, '', { root: true })

  return returnResult
}

export function onApiCatchError(context: any, error: any, actionDescription: string): void {
  const responseBody = error && error.response && error.response.data || 'No Response Body'
  // tslint:disable-next-line:no-console
  consoleLog(`API Error Response Body: ${JSON.stringify(responseBody)}
    \nFull Error: ${JSON.stringify(error)}`)
  if (error.response) {
    let extraInfo = ''
    if (error.response.data !== undefined && error.response.data !== null &&
            error.response.data.message !== undefined && error.response.data.message.length > 0) {
      extraInfo = ' Message: ' + error.response.data.message
    }
    context.commit(AppConst.StoreHelper.SetApiErrorMutationName,
      `Something went wrong ${actionDescription}. The response code: ${error.response.status}.${extraInfo}`, { root: true })
  } else if (error.request) {
    context.commit(AppConst.StoreHelper.SetApiErrorMutationName,
      `Something went wrong ${actionDescription}. The request was made but no response was received.`, { root: true })
  } else {
    context.commit(AppConst.StoreHelper.SetApiErrorMutationName,
      `Something went wrong ${actionDescription}.`, { root: true })
  }
  context.commit(AppConst.StoreHelper.loadingMutationName, false, { root: true })
  context.commit(AppConst.StoreHelper.loadingModalMutationName, false, { root: true })
}

// A dev util function to skip the real api request and commit fake data to the mutation.
export function fakeApiRequestSuccess(context: any, mutation: string, mutationData: any): Promise<boolean> {
  if (!mutationData) {
    // This is just here to show example usage, EG:
    // const mutationData: any = { propName: 'Fake Prop Value' }
    mutationData = [{ Name: 'Fake Tenant Name' }]
  }
  context.commit(mutation, mutationData)
  context.commit(AppConst.StoreHelper.loadingMutationName, false, { root: true })
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}
