export default {} // exported here and imported in App.vue so that webpack includes this js

const GetIsLogging = () => {
  if (typeof (Storage) !== 'undefined') {
    return localStorage.getItem('jigIsLogging') === 'true'
  } else {
    return false; // If the browser does not support local storage, logging is always false.
  }
}

const SetIsLogging = (isLogging) => {
  if (typeof (Storage) !== 'undefined') {
    localStorage.setItem('jigIsLogging', isLogging ? 'true' : 'false')
    return isLogging
  } else {
    return false
  }
}

const ToggleLog = () => {
  const isInDevEnv = window.location.hostname === 'localhost' || window.location.hostname === 'dashboard.dev.jig.space'
  SetIsLogging(!GetIsLogging())
  return GetIsLogging() ? 'Logging is ON' : isInDevEnv ? `Logging is still ON because env is ${window.location.hostname}` : `Logging is OFF`
}

const JigConsoleLog = (message) => {
  if (window.location.hostname === 'localhost'
    || window.location.hostname === 'dashboard.dev.jig.space' // We always log for localhost and dev
    || GetIsLogging()) {
    // eslint-disable-next-line
    console.log(message)
  }
}

const JigConsoleError = (message, errorData) => {
  if (window.location.hostname === 'localhost'
    || window.location.hostname === 'dashboard.dev.jig.space' // We always log for localhost and dev
    || GetIsLogging()) {
    // eslint-disable-next-line
    console.error(message, errorData || '')
  }
}

// Usage: in chrome console as `toggleLog()`
window.toggleLog = ToggleLog

// Usage:
// - in vue files as `consoleLog('message')`
// - in js files as `consoleLog('message')`
// - in ts files as `consoleLog('message')` but you have to `declare var consoleLog : any;` at the top of the file
window.consoleLog = JigConsoleLog
// Usage:
// - in vue files as `consoleError('message', error)`, `consoleError('message', payload)`
// - in js files as `consoleError('message', error)`, `consoleError('message', payload)`
// - in ts files as `consoleError('message', error)`, `consoleError('message', payload)` but you have to `declare var consoleError : any;` at the top of the file
window.consoleError = JigConsoleError
