import { Platforms, Version } from '@/store/modules/version/types'

export namespace VersionConstants {
  export enum VersionJson {
    ID = 0,
    Application = 'jigspace',
    Platform = 'desktop',
    Name = '',
    CreatedAt = '0001-01-01T00:00:00Z',
    UpdatedAt = '0001-01-01T00:00:00Z',
    SupportStart = '',
    SupportEnd = '2040-01-01T23:59:59Z',
    ExpiryReminderStartOffsetHRS = 168,
    UpgradeCheckFrequencyHRS = 24,
    UpgradeReminderMessage = 'This version of JigSpace ,will expire soon and will no longer be supported. You should update as soon as possible.',
    Version = '',
    Build = -1,
    downloadFileUri = '',
  }

  export const VersionEntry: Version = {
    Application: VersionJson.Application as string,
    Name: VersionJson.Name as string,
    Platform: VersionJson.Platform as string,
    Archived: false,
    SupportStart: VersionJson.SupportStart as string,
    SupportEnd: VersionJson.SupportEnd as string,
    ExpiryReminderStartOffsetHRS: VersionJson.ExpiryReminderStartOffsetHRS as number,
    UpgradeCheckFrequencyHRS: VersionJson.UpgradeCheckFrequencyHRS as number,
    UpgradeReminderMessage: VersionJson.UpgradeReminderMessage,
    Version: VersionJson.Version as string,
  }

  enum PlatformsJson {
    desktop = 'Desktop',
    ios = 'iOS',
  }

  export const PlatformsOptions: Platforms = {
    desktop: PlatformsJson.desktop,
    ios: PlatformsJson.ios,
  }

  export enum VersionDefaults {
    DefaultPlatform = 'desktop',
    DefaultApplication = 'jigspace',
  }

  export const ApplicationItems: Array<VersionDefaults> = [VersionDefaults.DefaultApplication]
}
