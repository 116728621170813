
const invalidNumber = -1

export default {
  modelsLibrary: [],
  libraryEntry: {
    DisplayName: '',
    Tags: [],
    Categories: [],
    ThumbnailUrl: '',
  },
  defaultLibraryCategory: {
    DisplayName: 'New Category',
    CategoryOrder: invalidNumber,
    StateId: 0,
    StateName: 'Under Construction',
  },
  libraryCategories: {
    Categories: [],
    CategoryOrder: [],
  },
  libraryCategoryStates: {
    States: [],
  },
}
