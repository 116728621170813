import { apiRequest, apiRequestCustomConfig } from '@/store/modules/ApiRequestHelper'
import { AppConst } from '@/store/modules/constants'
import {
  ChangeJigOwnerRequest,
  ChangeJigOwnerResult,
  ChangeOwnerTenantRequest,
  JigMetaData,
  JigPermissionCreateRequest,
  JigPermissionDeleteRequest,
  JigPermissionUpdateRequest,
  JigShareRequest,
  JigShareURLData,
  JigVisibilities,
  MyJig,
} from '@/store/modules/jig/types'
import {
  ReportRequest,
  UploadThumbnailRequest,
} from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

declare const consoleLog: any
declare const consoleError: any

const CancelToken = Axios.CancelToken
let myJigsListCall: any = null
let teamJigsListCall: any = null

export default {
  executeStatisticReport(context: any, reportRequest: ReportRequest): Promise<boolean> {
    let urlParams = {}
    let myMutation = ''
    const tenantId = this.getMyTenantID(context.rootState.app)

    switch (reportRequest.Name) {
      case AppConst.Report.TenantDashboard:
        myMutation = 'updateJigViewGraph'
        urlParams = {
          name: reportRequest.Name,
          jig_hash_id: reportRequest.JigHashId,
          start_date: reportRequest.StartDate,
          end_date: reportRequest.EndDate,
          comparePreviousPeriod: reportRequest.ComparePreviousPeriod,
        }
        break
      case AppConst.Report.JigViewActivity:
        myMutation = 'updateJigViewGraph'
        urlParams = {
          name: reportRequest.Name,
          jig_hash_id: reportRequest.JigHashId,
          start_date: reportRequest.StartDate,
          end_date: reportRequest.EndDate,
          comparePreviousPeriod: reportRequest.ComparePreviousPeriod,
        }
        break
      case AppConst.Report.MostPopularJig:
        myMutation = 'updateMostPopularJigGraph'
        urlParams = {
          name: reportRequest.Name,
          top: reportRequest.Limit,
          reportDays: reportRequest.LookBackDays,
          comparePreviousPeriod: reportRequest.ComparePreviousPeriod,
        }
        break
      case AppConst.Report.AppActivities:
        myMutation = 'updateAppActivitiesGraph'
        urlParams = {
          name: reportRequest.Name,
          // some other query params...
        }
        break
      default:
        myMutation = 'mutation' + reportRequest.Name
    }

    return apiRequest(
      {
        action: AppConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.statisticsUrl}`,
        params: urlParams,
      },
      context,
      myMutation,
      `executing report ${reportRequest.Name} using mutation ${myMutation}`,
      AppConst.StoreHelper.isStatisticsLoadingMutationName,
      {
        [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )
  },

  async loadMyJigs(context: any): Promise<boolean> {
    myJigsListCall = CancelToken.source()

    /**
     * As My Jigs page and Team Jigs page are using the same view template,
     * if route changes while GET request is still ongoing plus the Jigs list is reasonable long,
     * it will overwrite the result of new GET request call on new route.
     * Or there will be a noticeable time delay before new list appears.
     * So we need to cancel any ongoing call to avoid situation like Team Jigs are showing on My Jigs page
     * or My Jigs showing on Team Jigs page.
     */
    if (teamJigsListCall) {
      teamJigsListCall.cancel('Aborting request to get Team Jigs before get My Jigs request.')
    }

    const urlParams = {
      editable: 'false',
    }

    const result = await apiRequest({
      action: AppConst.HTTPAction.GET,
      url: `${AppConst.APIEndpoint.myJigsV2Url}`,
      params: urlParams,
      cancelToken: myJigsListCall.token,
    }, context, 'updateMyJigs', `getting my jigs`)

    myJigsListCall = null

    return result
  },

  async loadTeamJigs(context: any): Promise<boolean> {
    teamJigsListCall = CancelToken.source()

    if (myJigsListCall) {
      myJigsListCall.cancel('Aborting request to get My Jigs before get Team Jigs request.')
    }

    const urlParams = {
      editable: 'false',
    }

    const tenantId = this.getMyTenantID(context.rootState.app)

    const result = await apiRequest(
      {
        action: AppConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.tenantsV1Url}/${tenantId}${AppConst.APIEndpoint.tenantsJigsUrl}`,
        params: urlParams,
        cancelToken: teamJigsListCall.token,
      },
      context,
      'updateTeamJigs',
      `getting team jigs`,
      AppConst.StoreHelper.loadingMutationName,
      {
        [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      }
    )

    teamJigsListCall = null

    return result
  },

  loadMyJigsWithMetrics(context: any): Promise<boolean> {
    const urlParams = {
      editable: 'false',
    }
    return apiRequest({
      action: AppConst.HTTPAction.GET,
      url: `${AppConst.APIEndpoint.myJigsV2UrlMetrics}`,
      params: urlParams,
    }, context, 'updateMyJigs', `getting my jigs`)
  },

  loadJigMetadata(context: any, payload: any): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.rootState.app)
    return apiRequest(
      {
        action: AppConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.jigsV2Url}/${payload.jigId}/${AppConst.APIEndpoint.jigMetadataV1}`,
      },
      context,
      'updateJigMetadata',
      `getting jig ${payload.jigId}`,
      AppConst.StoreHelper.loadingMutationName,
      {
        [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
      })
  },

  deleteJigById(context: any, payload: any): Promise<boolean> {
    if (!payload.jigId || !payload.jigFormatVersion) {
      return Promise.reject('Need a jigId and jigFormatVersion')
    }
    let url = AppConst.APIEndpoint.myJigsUrl.concat('/', payload.jigId)
    if (payload.jigFormatVersion >= 4) { // use v2 if the format version is > 4
      url = AppConst.APIEndpoint.myJigsV2Url.concat('/', payload.jigId)
    }
    return apiRequest({
      action: AppConst.HTTPAction.DELETE,
      url,
    }, context, 'deleteFromMyJigsById', `deleting jig id: ${payload.jigId}`)
  },

  copyJigById(context: any, payload: any): Promise<boolean> {
    const urlParams = {
      action: 'copy',
      name: payload.newName,
    }
    // Response object we get back on a 201 of the copy endpoint
    // MeJigV2CopyResponse is the structure returned when you copy a V2 Jig.
    // type MeJigV2CopyResponse struct {
    //     Status      string `json:"status"`
    //     HashId      string `json:"id"`
    //     Name        string `json:"name"`
    //     DeeplinkURL string `json:"deeplinkURL,omitempty"`
    //     Visibility  string `json:"visibility"`
    // }
    return apiRequest({
      action: AppConst.HTTPAction.POST,
      url: `${AppConst.APIEndpoint.meJigsV2Url}/${payload.jigId}`,
      params: urlParams,
    },
    context, 'updateCopyJigResult', `copying jig ${payload.jigId} with new name ${payload.newName}`,
    AppConst.StoreHelper.loadingMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.tenantId,
    })
  },

  async updateJigMetadata(context: any, payload: JigMetaData, queryParams?: any): Promise<boolean> {
    if (!payload || !payload.Id || payload.Version == null) {
      consoleError(`Invalid updateJigMetadata payload. Required payload type: JigMetaData
      Received payload:`, payload)
      return new Promise(() => false)
    } else {
      const tenantId = this.getMyTenantID(context.rootState.app)

      const requestQueryParam = queryParams ? {
        ...queryParams,
        version: payload.Version,
      } : { version: payload.Version }
      const results: any = {}

      results.isSuccess = await apiRequest(
        {
          action: AppConst.HTTPAction.PUT,
          url: `${AppConst.APIEndpoint.meJigsV2Url}/${payload.Id}/${AppConst.APIEndpoint.jigMetadataV1}`,
          body: payload,
          params: requestQueryParam,
        },
        context,
        'updateSaveJigResult',
        `updating metadata of Jig ${payload.Id}`,
        AppConst.StoreHelper.loadingMutationName,
        {
          [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
        },
        (context: any, error: any, errorMsg: string) => {
          if (error.response) {
            results.status = error.response.status
          } else if (error.message.includes(`status code ${AppConst.nonSystemErrors.unprocessableEntity.status}`) || error.message.includes('E040019')) {
            results.status = AppConst.nonSystemErrors.unprocessableEntity.status
          }
        },
      )

      return results
    }
  },

  async updateJigVisibility(context: any, payload: JigVisibilities): Promise<boolean> {
    if (!payload || !payload.visibility || !payload.version == null) {
      consoleError(`Invalid updateJigVisibility payload. Required payload:
        JigVisibilities {
          jigId: string
          visibility: string
          regenPassword: boolean
          version: number
        }
      Received payload:`, payload)
      return new Promise(() => false)
    } else {
      const tenantId = this.getMyTenantID(context.rootState.app)
      const results: any = {}

      results.isSuccess = await apiRequest(
        {
          action: AppConst.HTTPAction.PUT,
          url: `${AppConst.APIEndpoint.myJigsV2Url}/${payload.jigId}/visibility/${payload.visibility}`,
          body: payload,
          params: {
            regenPassword: payload.regenPassword,
            version: payload.version
          },
        },
        context,
        'updateSaveJigVisibilityResult',
        `updating jig ${payload.jigId} visibility`,
        AppConst.StoreHelper.loadingMutationName,
        {
          [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
        },
        (context: any, error: any, errorMsg: string) => {
          if (error.response) {
            results.status = error.response.status
          } else if (error.message.includes(`status code ${AppConst.nonSystemErrors.unprocessableEntity.status}`) || error.message.includes('E040019')) {
            results.status = AppConst.nonSystemErrors.unprocessableEntity.status
          }
        },
      )

      return results
    }
  },

  loadJigPermissions(context: any, payload: any): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.rootState.app)
    return apiRequest({
      action: AppConst.HTTPAction.GET,
      url: `${AppConst.APIEndpoint.myJigsV2Url}/${payload.jigId}/permission`,
    },
    context,
    'updateJigPermissions',
    `getting jig permissions`,
    AppConst.StoreHelper.loadingModalMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
    })
  },

  createJigPermission(context: any, payload: JigPermissionCreateRequest): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.rootState.app)
    return apiRequest({
      action: AppConst.HTTPAction.POST,
      url: `${AppConst.APIEndpoint.myJigsV2Url}/${payload.JigId}/permission`,
      params: payload.UrlParams,
      // body: payload.Perm,
    },
    context,
    'updateJigPermissions',
    `creating jig permission`,
    AppConst.StoreHelper.loadingModalMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
    })
  },

  deleteJigPermission(context: any, payload: JigPermissionDeleteRequest): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.rootState.app)
    return apiRequest({
      action: AppConst.HTTPAction.DELETE,
      url: `${AppConst.APIEndpoint.myJigsV2Url}/${payload.ResID}/permission/${payload.PermID}`,
    },
    context,
    '',
    `deleting jig permission`,
    AppConst.StoreHelper.loadingModalMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
    })
  },

  updateJigPermission(context: any, payload: JigPermissionUpdateRequest): Promise<boolean> {
    const tenantId = this.getMyTenantID(context.rootState.app)
    return apiRequest({
      action: AppConst.HTTPAction.PUT,
      url: `${AppConst.APIEndpoint.myJigsV2Url}/${payload.Perm.ResID}/permission/${payload.Perm.ID}`,
      body: payload.Perm,
    },
    context,
    '', // We don't do anything with the response body as we need to request all permissions again.
    `updating jig permission`,
    AppConst.StoreHelper.loadingModalMutationName,
    {
      [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: tenantId,
    })
  },

  clearJigShareLink(context: any, payload: JigShareURLData) {
    payload.requestInFlight = false
    payload.shareURL = ''
    context.commit('setRequestingJigShareLink', payload)
  },

  async changeJigOwner(context: any, payload: ChangeJigOwnerRequest): Promise<{
    isSuccessful: boolean
    terminate: boolean
  }> {
    const msg = `changing jig owner. Jig ID: to ${payload.JigId}, email: ${payload.DesiredOwnerEmail}`
    if (payload.JigId.length === 0 || payload.DesiredOwnerEmail.length === 0) {
      consoleError(`Invalid changeJigOwner payload. Required payload:
        ChangeJigOwnerRequest {
          JigId: string
          DesiredOwnerEmail: string
          DesiredOwnerTenantID?: number
        }
      Received payload:`, payload)
      context.commit(AppConst.StoreHelper.SetApiErrorMutationName, `Invalid changeJigOwner payload received.`, { root: true })
      return new Promise(() => ({
        isSuccessful: false,
        terminate: false,
      }))
    } else {
      const urlParams: any = {
        email: payload.DesiredOwnerEmail,
      }
      if (payload.action) {
        urlParams.action = payload.action
      }
      if (payload.DesiredOwnerTenantID) {
        urlParams.tenantID = payload.DesiredOwnerTenantID
      }

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${this.authService().accessToken}`,
        },
        params: urlParams,
      }

      try {
        const response: any = await Axios.put(`${AppConst.apiDomain}${AppConst.APIEndpoint.myJigsV2Url}/${payload.JigId}/owner`, {}, config)

        if (response.data) {
          context.commit('updateChangeJigOwnerResult', response.data as ChangeJigOwnerResult)

          return {
            isSuccessful: response.data.status === 'success',
            terminate: response.data.message !== `Resource not found: ${payload.JigId}`,
          }
        } else {
          consoleLog(`Jig ownership change failed, response was: ${JSON.stringify(response)}`)
          context.commit(AppConst.StoreHelper.SetApiErrorMutationName, `Something went wrong when changing Jig ownership`, { root: true })
          return {
            isSuccessful: false,
            terminate: false,
          }
        }
      } catch (error: any) {
        consoleLog(`Jig ownership change failed, error: ${JSON.stringify(error.response)}`)
        const terminateAllRequests = error.response.data.message !== `Resource not found: ${payload.JigId}`

        if (terminateAllRequests) {
          context.commit(AppConst.StoreHelper.SetApiErrorMutationName, `Error happened when changing Jig ownership. ${error.response.data.message}`, { root: true })
        }

        return {
          isSuccessful: false,
          terminate: terminateAllRequests,
        }
      }
    }
  },

  changeJigOwnerTenant(context: any, payload: ChangeOwnerTenantRequest): Promise<boolean> {
    const msg = `changing jig owner. Jig ID: to ${payload.JigId}, tenant ID: ${payload.DesiredOwnerTenantID}`
    if (payload.JigId.length === 0 || payload.DesiredOwnerTenantID === TenantHelpers.InvalidTenantID) {
      consoleError(`Invalid changeJigOwnerTenant payload. Required payload:
        ChangeOwnerTenantRequest {
          JigId: string
          DesiredOwnerTenantID: number
        }
      Received payload:`, payload)
      return new Promise(() => false)
    } else {
      const urlParams = {
        tenantID: payload.DesiredOwnerTenantID,
      }
      return apiRequest({
        action: AppConst.HTTPAction.PUT,
        url: `${AppConst.APIEndpoint.myJigsUrl}/v2/${payload.JigId}/owner`,
        params: urlParams,
      }, context, 'updateChangeJigOwnerResult', msg)
    }
  },

  async requestJigDeeplinkURL(context: any, payload: JigShareRequest): Promise<boolean> {
    if (payload == null) {
      return new Promise((reject) => reject(false))
    }

    return requestBranchJigLinkViaHttpApi(context, payload.jig, payload.branchIOCustomData, payload.targetApp)
  },

  async uploadJigThumbnail(context: any, payload: UploadThumbnailRequest): Promise<boolean> {
    if (payload == null || payload.Id.length === 0 || !payload.TenantId || payload.Thumbnail.size === 0 || payload.version == null) {
      consoleError(`Invalid uploadJigThumbnail payload. Required payload:
        UploadThumbnailRequest {
          Id: string
          TenantId: number
          Thumbnail: File
          IsFirstThumbnail: boolean
          version?: number
          DateUpdated?: string
        }
      Received payload:`, payload)
      return new Promise(() => false)
    } else {
      const file = new Blob([payload.Thumbnail], {
        type: 'application/octet-stream',
      })
      const formData = new FormData()
      // We change the thumbnail name before we send it as form data
      formData.append('image', file, `jig-${payload.Id}-color`)
      const urlParams = {
        cl: '6x6', // Compression Level Block Size (copied from Unity client)
        mode: 'medium', // Compression Speed (copied from Unity client)
        version: payload.version, // Jig's current version value in JigMetadata
      }
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${this.authService().accessToken}`,
          [AppConst.RequestHeaders.CustomHeaderKeyTenantId]: payload.TenantId,
        },
        params: urlParams,
        timeout: 300 * 1000, // 5 minutes
        baseURL: `${AppConst.apiDomain}`,
      }
      const request = {
        action: payload.IsFirstThumbnail ? AppConst.HTTPAction.POST : AppConst.HTTPAction.PUT,
        url: `${AppConst.APIEndpoint.myJigsV2Url}/${payload.Id}/thumbnail`,
        body: formData,
      }
      const requestResults: any = {}
      requestResults.isSuccess = await apiRequestCustomConfig(
        request,
        context,
        'uploadThumbnailResult',
        `uploading new Jig thumbnail. Jig ID: ${payload.Id}, file: ${payload.Thumbnail.name}`,
        config,
        undefined,
        (context: any, error: any, errorMsg: string, onApiCatchError: any) => {
          if (error.response) {
            requestResults.status = error.response.status
            // When SUP error happens, we use a custom error message in UI, all other errors should use default API error catch event.
            if (requestResults.status !== AppConst.nonSystemErrors.unprocessableEntity.status && requestResults.status !== AppConst.nonSystemErrors.conflict.status) {
              onApiCatchError(context, error, errorMsg)
            }
          } else if (error.message.includes(`status code ${AppConst.nonSystemErrors.unprocessableEntity.status}`) || error.message.includes('E040019')) {
            requestResults.status = AppConst.nonSystemErrors.unprocessableEntity.status
          } else if (error.message.includes(`status code ${AppConst.nonSystemErrors.conflict.status}`)) {
            requestResults.status = AppConst.nonSystemErrors.conflict.status
          }
        }
      )
      return requestResults
    }
  },
}

async function requestBranchJigLinkViaHttpApi(context: any, jig: MyJig|JigMetaData, requestData: any, targetApp: string): Promise<boolean> {
  if (context === null) {
    return new Promise((reject) => reject(false))
  }

  const jigDeeplinkURLRequesting = (jig as MyJig).deeplinkURLRequesting || (jig as JigMetaData).DeeplinkURLRequesting

  // Can't request the same share link at the same time.
  if (jigDeeplinkURLRequesting !== undefined && jigDeeplinkURLRequesting) {
    return new Promise((reject) => reject(false))
  }

  const deeplink = (jig as MyJig).deeplinkURL || (jig as JigMetaData).DeeplinkURL

  // We've already got a share url, return success
  if (deeplink !== undefined && deeplink.length > 0) {
    return new Promise((resolve) => resolve(true))
  }

  const jigID = (jig as MyJig).id || (jig as JigMetaData).Id

  context.commit('setRequestingJigShareLink', {
    jigId: jigID,
    requestInFlight: true,
    shareURL: '',
  } as JigShareURLData)

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${this.authService().accessToken}`,
    },
  }

  const reqBody = {
    ...requestData,
  }
  let response!: AxiosResponse<any>

  const url = `${AppConst.apiDomain}${AppConst.APIEndpoint.jigsV1Url}/${jigID}/deeplinks/v1?target_app=${targetApp}`
  response = await Axios.post(url, reqBody, config)
  if (response.data && response.data.url !== undefined && response.data.url.length > 0) {
    consoleLog(`Jig Share URL: ${response.data.url}`)
    context.commit('setRequestingJigShareLink', {
      jigId: jigID,
      requestInFlight: false,
      shareURL: response.data.url,
    } as JigShareURLData)
    return true
  } else {
    consoleLog(`Jig Share URL was not successful, response was: ${JSON.stringify(response)}`)
    context.commit('setRequestingJigShareLink', {
      jigId: jigID,
      requestInFlight: false,
      shareURL: '',
    } as JigShareURLData)
    context.commit(AppConst.StoreHelper.SetApiErrorMutationName, `Something went wrong getting a link for this Jig.`, { root: true })
    return false
  }
}
