import { AppConst } from '@/store/modules/constants'
import { ImageValidationResult } from '@/store/types'
import sizeOf from 'image-size'

export class ValidationRules {
  public static readonly RequiredName: any = [
    (v: any) => v != null || 'Name is required',
    (v: any) => v !== undefined || 'Name is required',
    (v: any) => v !== '' || 'Name is required',
    (v: any) => undefined !== v && v.length <= 254 || 'Name must be less than 254 characters',
  ]

  public static readonly PngImageUpload: any = [
    (v: any) => !v || v.type === 'image/png' || 'Image must be a png',
  ]

  public static readonly ValidModelFormats: string = '.obj,.fbx,.stp,.step,.stl,.sldz,.sldprt,.sldasm'
  // There is a vague order to how we show formats: obj/fbx -> consumer formats first, the rest is cad/pro
  private static readonly ValidModelFormatsPretty: string = '.OBJ, .FBX, .STP, .STEP, .STL, .SDLZ, .SLDPRT, .SLDASM'

  public static readonly ModelUploadFiles: any = [
    (files: File[]) => {
      if (files === undefined || files === null || files.length === 0) {
        return 'No files provided'
      }

      for (let index = 0; index < files.length; index++) {
        const file = files[index]
        if (file.size === 0) {
          return `${file.name} size must be greater than 0 bytes`
        }

        if (file.size > 300000000) {
          return `${file.name} size must be less than 300MB`
        }

        if (file.name === undefined || file.name === null) {
          return `${file.name} must be of type: ${ValidationRules.ValidModelFormatsPretty}`
        }
        let v = file.name.split('.').pop()
        v = v === undefined ? 'noextension' : v; // Protection from undefined
        v = '.' + v.toString().toLowerCase()
        const arrFormats = ValidationRules.ValidModelFormats.split(',')
        if (arrFormats.indexOf(v) == -1) {
          return `${file.name}: unsupported format. We currently only support ${ValidationRules.ValidModelFormatsPretty}`
        }
      }
      return true
    },
  ]

  public static readonly RequiredNumber: any = [
    (v: any) => v != null || 'Number is required',
    (v: any) => v !== '' || 'Number is required',
    (v: any) => !isNaN(v) || 'Number must be a number',
  ]

  public static readonly RequiredNoneNegativeNumber: any = [
    (v: any) => v != null || 'Number is required',
    (v: any) => v !== '' || 'Number is required',
    (v: any) => !isNaN(v) || 'Number is required',
    (v: any) => v >= 0 || 'Positive number is required',
  ]

  public static readonly RequiredPositiveNumber: any = [
    (v: any) => v != null || 'Number is required',
    (v: any) => v !== '' || 'Number is required',
    (v: any) => !isNaN(v) || 'Number is required',
    (v: any) => v > 0 || 'Positive number is required',
  ]

  private static readonly jigHashIdsPattern = /^\b[a-zA-Z0-9]{8}\b(?:,\s*\b[a-zA-Z0-9]{8}\b)*$/
  public static readonly RequiredJigHashID: any = [
    (v: any) => v != null || 'Jig Hash ID is required',
    (v: any) => v !== undefined || 'Jig Hash ID is required',
    (v: any) => v !== '' || 'Jig Hash ID is required',
    (v: any) => {
      const regexp = new RegExp(ValidationRules.jigHashIdsPattern)
      const res = regexp.test(v)
      return res || 'Invalid Jig Hash ID(s) or invalid delimiter. Separate multiple IDs using "," comma character.'
    },
  ]

  // Email validation regex comes from:-
  // https://community.auth0.com/t/email-validation-rules-please-make-them-public-knowledge/29532
  // We have to split the e-mail regex pattern over two lines otherwise we get linter errors due to line length.
  private static readonly emailPatternPart1 = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@/
  private static readonly emailPatternPart2 = /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  private static readonly emailPattern = ValidationRules.emailPatternPart1.source + ValidationRules.emailPatternPart2.source

  public static readonly RequiredEmail: any = [
    (v: any) => v != null || 'Required.',
    (v: any) => v !== '' || 'Required.',
    (v: any) => v !== undefined || 'Required.',
    (v: any) => {
      const regexp = new RegExp(ValidationRules.emailPattern)
      const res = regexp.test(v)
      return res || 'Invalid e-mail.'
    },
  ]

  public static readonly OptionalEmail: any = [
    (v: any) => {
      if (v === undefined || v === null || v === '') {
        return true
      }
      const regexp = new RegExp(ValidationRules.emailPattern)
      const res = regexp.test(v)
      return res || 'Invalid e-mail.'
    },
  ]

  public static readonly OptionalEmailList: any = [
    (emails: string[]) => {
      // A valid array must be supplied
      if (emails === null || emails === undefined) {
        return false
      }
      // the array can be empty
      if (emails.length == 0) {
        return true
      }

      let emailCount = emails.length
      let displayIndex = 1

      for (const v of emails) {
        if (v === '' || v === null || v === undefined) {
          let errMsg = ''
          if (emailCount === 1) {
            errMsg = 'Invalid e-mail address'
          } else {
            errMsg = 'Invalid e-mail address at index: ' + displayIndex
          }
          return false || errMsg
        }
        const regexp = new RegExp(ValidationRules.emailPattern)
        const res = regexp.test(v)
        if (!res) {
          const errMsg = 'Invalid e-mail address: ' + v
          return res || errMsg
        }
        displayIndex++
      }
      return true
    },
  ]

  private static readonly urlPartialPattern = /\..|:[0-9]+/i
  // private static readonly urlPattern = //
  public static readonly OptionalUrl: any = [
    (v: any) => {
      if (v === undefined || v === null || v === '') {
        return true
      }

      let tempVal = v

      if (!tempVal.startsWith('mailto:')) {
        const regexp = new RegExp(ValidationRules.urlPartialPattern)
        const res = regexp.test(tempVal)
        if (res && !tempVal.includes('://')) {
          tempVal = `http://${tempVal}`
        }
      }

      // Use JS URL() constructor to validate url value.
      try {
        new URL(tempVal)
        return true
      } catch (error) {
        return 'Invalid url.'
      }
    },
  ]

  // private static readonly urlPattern = //
  public static readonly OptionalTags: any = [
    (v: any) => {
      if (v === undefined || v === null || v === '') {
        return true
      }

      const hasNoComma = v && !v.includes(',')
      if (!hasNoComma) return 'Comma is not allowed'

      const regexp = new RegExp(AppConst.nonPrintableCopyRegex)
      const res = regexp.test(v)

      return !res || 'Invalid character detected'
    },
  ]

  public static ImageSizeLibraryThumbnail(v: File): Promise<ImageValidationResult> {
    return ValidationRules.ImageSize(v, 0, 0, 320, 287)
  }

  public static ImageSizeJigThumbnail(v: File): Promise<ImageValidationResult> {
    return ValidationRules.ImageSize(v, 0, 0, 512, 386)
  }

  public static ImageSize(
    v: File, minWidth: number, minHeight: number,
    maxWidth: number, maxHeight: number): Promise<ImageValidationResult> {
    return new Promise<ImageValidationResult>(async (resolve) => {
      const result: ImageValidationResult = {
        valid: false,
        xMin: 0,
        yMin: 0,
        xMax: maxWidth,
        yMax: maxHeight,
        x: 0,
        y: 0,
      }

      // Async load image details and check dimensions
      const fr = new FileReader()
      fr.onload = () => {
        const ab: any = fr.result
        const buffer = new Buffer(ab)
        const imageDetails = sizeOf(buffer)
        result.valid = (imageDetails.width >= minWidth && imageDetails.width <= result.xMax &&
          imageDetails.height >= minHeight && imageDetails.height <= result.yMax)
        result.x = imageDetails.width
        result.y = imageDetails.height
        result.xMin = minWidth
        result.xMax = maxWidth
        result.yMin = minHeight
        result.yMax = maxHeight
        resolve(result)
      }

      fr.onabort = () => {
        resolve(result)
      }

      fr.onerror = () => {
        resolve(result)
      }
      fr.readAsArrayBuffer(v)
    })
  }
}
