import { AppConst } from '@/store/modules/constants'

const invalidNumber = -1

export default {
  apiError: '',
  drawer: null,
  color: 'warning',
  image: '',
  initializing: false,
  loading: true,
  loadingModal: false,
  // The selected row count to show in data tables
  libraryItemsPerPage: 10,
  myJigsItemsPerPage: 10,
  teamJigsItemsPerPage: 10,
  tenantsItemsPerPage: AppConst.defaultTenantsPagination.limit,
  tenantUsersItemsPerPage: invalidNumber, // all
  allTenantsCurrentPage: 1,
  allTenantsSearchCriteria: '',
}
