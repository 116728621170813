import jigCategoryOptionsJson from '@/store/modules/JigCategoryOptions.json'
import { JigSummaryStats, ReportJigSummaryStats } from '@/store/types'

export default {
  jigDailyActivityData: [] as JigSummaryStats[],
  jigDailyViewsData: [] as ReportJigSummaryStats[],
  myJigs: [],
  teamJigs: [],
  jigMetadata: {
    ProjectName: '',
    FormatVersion: 0,
  },
  jigDetailPermissions: null,
  copyJigResult: null,
  saveJigResult: null,
  saveJigVisibilityResult: null,
  isStatisticsLoading: false,
  jigCategoryOptions: jigCategoryOptionsJson,
  changeJigOwnerResult: {
    message: '',
    status: '',
  },
  uploadThumbnailResult: {
    status: '',
    thumbnailURL: '',
  },
  embedParamsConfig: {},
}
