import { getInstance } from '@/plugins/auth0'
import { AppConst } from '@/store/modules/constants'

declare var consoleLog: any

export class AppHelper {
  public static getBranchLinkAlias() {
    const dashboardEnv = process.env.VUE_APP_DASHBOARD_ENV
    if (dashboardEnv === 'production') {
      return 'post-signup-open'
    } else if (dashboardEnv === 'develop' || dashboardEnv === 'localhost') {
      return 'post-signup-open-dev'
    } else {
      return `post-signup-open-${dashboardEnv}`
    }
  }

  public static openJigSpaceUrl() {
    const authService = getInstance()
    const profile: any = authService.user
    const emailValue: string = profile ? profile.email : ''

    return `https://link.jig.space/${AppHelper.getBranchLinkAlias()}?email=${encodeURIComponent(emailValue)}&token=${encodeURIComponent(authService.accessToken || '')}`
  }

  // TODO: when `team` tier is completely replaced to `starter` tier, we can safely remove this function
  public static getTierType(value: string) {
    if (value === 'team') {
      return 'starter'
    } else {
      return value
    }
  }

  public static stringSanitiser(value: string) {
    return value.replace(AppConst.nonPrintableCopyRegex, '')
  }

  // So far we only have JSON safe object to copy. If object contains non-JSON-safe value (e.g., functions, undefined, NaN), or a Date objects (not Data string), we will need to write a proper deep copy function.
  public static deepCopyNestedJsonSafeObject(jsonSafeObject: any) {
    return JSON.parse(JSON.stringify(jsonSafeObject))
  }

  public static isObject(object: any) {
    return object != null && typeof object === 'object'
  }

  public static isDeepEqual(object1: any, object2: any) {
    const objKeys1 = Object.keys(object1)
    const objKeys2 = Object.keys(object2)

    if (objKeys1.length !== objKeys2.length) {
      return false
    }

    for (var key of objKeys1) {
      const value1 = object1[key]
      const value2 = object2[key]

      const isObjects = this.isObject(value1) && this.isObject(value2)

      if ((isObjects && !this.isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
        return false
      }
    }

    return true
  }

  public static deleteCookie(name: string) {
    if (document.cookie.split(';').some((item) => item.trim().startsWith(name + '='))) {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
      consoleLog(`Cookie '${name}' has been deleted.`)
    } else {
      consoleLog(`Cookie '${name}' does not exist.`)
    }
  }
}
