import {
  CustomerResponse,
  StripeSubscriptionClass
} from '@/modules/subscription/types'
import { Permissions } from '@/security/permissions'
import {
  Tenant,
  TenantLogoImageResponse,
  TenantSearchResponse,
  TenantSetupJob,
  TenantToken,
  TenantUser,
  TenantUserCountResponse,
  UserRoleDefinition
} from '@/store/modules/app/types'
import { AppConst } from '@/store/modules/constants'
import {
  CreateCheckoutResponse,
  CreateCustomerPortalResponse,
  DynaconfConfig,
  PermissionActions,
  QueuedStatusResult,
  SetupIntentResponse
} from '@/store/types'
import { ListHelpers } from '@/utils/list-helpers'
import { TenantHelpers } from '@/utils/tenant-helpers'

declare var consoleLog: any

function _setMyTenantIndex(state: any, tenantIndex: number) {
  let resetLibraryDataRequired = false

  if (tenantIndex < state.myTenants.length) {
    const prevTenantIndex = state.myTenantIndex
    state.myTenantIndex = tenantIndex

    sessionStorage.setItem('myTenantIndex', state.myTenantIndex)
    if (prevTenantIndex !== tenantIndex) {
      // When the tenant index changes, make sure we're not hanging onto the previous tenants state.
      resetLibraryDataRequired = true
    }
    state.myTenant = state.myTenants[state.myTenantIndex]
  } else {
    state.myTenant = TenantHelpers.GetDefaultTenant()
  }

  state.myTenantTotalJigCount = -1 // reset Jig Count.

  return resetLibraryDataRequired
}

export default {
  updateUid(state: any, uid: number) {
    state.uid = uid
  },
  updateQueuedStatus(state: any, queuedStatusResult: QueuedStatusResult) {
    state.queuedStatusResult = queuedStatusResult
  },
  updateCreditCardSetupIntent(state: any, payload: SetupIntentResponse) {
    state.creditCardSetupIntentClientSecret = payload.stripeSetupIntentClientSecret
  },
  updateCustomerPaymentMethod(state: any, payload: CustomerResponse) {
    state.customerWithPaymentMethod = payload
  },
  updateSubscription(state: any, payload: StripeSubscriptionClass) {
    state.subscription = payload
  },
  setforceRerenderKey(state: any, payload: any) {
    state.forceRerenderKey += 1
  },
  updateCheckoutUrl(state: any, payload: CreateCheckoutResponse) {
    state.checkoutUrl = payload.CheckoutURL
  },
  updateCustomerPortalUrl(state: any, payload: CreateCustomerPortalResponse) {
    state.customerPortalUrl = payload.PortalURL
  },
  updateDynaconfConfig(state: any, payload: DynaconfConfig) {
    state.dynaconfConfig.pricing = payload.pricing
  },
  updateMyTenant(state: any, tenant: Tenant) {
    TenantHelpers.SetTenantDefaultLogoIfNull(tenant)
    const index = ListHelpers.getIndexById(state.myTenants, 'ID', tenant.ID)
    let tenantIndex = state.myTenantIndex
    if (index >= 0) {
      const existingTenant: Tenant = state.myTenants[index]
      // Preserve UserCount if it's not in the response.
      if (tenant.UserCount === undefined) {
        tenant.UserCount = existingTenant.UserCount
      }
      // Preserve LogoImage
      if (tenant.Logourl === existingTenant.Logourl) {
        tenant.LogoImage = existingTenant.LogoImage
      }
      state.myTenants[index] = Object.assign({}, tenant)
      tenantIndex = index
    } else {
      state.myTenants.push(Object.assign({}, tenant))
      tenantIndex = state.myTenants.length - 1
    }
    const isLibraryDataResetRequired = _setMyTenantIndex(state, tenantIndex)
    if (isLibraryDataResetRequired) {
      this.resetLibraryData()
    }
  },
  updateMyTenantLogo(state: any, imageData: string) {
    if (state.myTenantIndex !== TenantHelpers.InvalidTenantIndex) {
      const tenant = TenantHelpers.GetTenantByIndex(state.myTenantIndex, state.myTenants)
      if (tenant !== undefined) {
        tenant.LogoImage = 'data:image/png;base64,'.concat(imageData)
        if (tenant.LogoUpdated) {
          tenant.LogoUpdated = false
        }
      }
    }
  },
  updateMyTenantLogoByID(state: any, payload: TenantLogoImageResponse) {
    const tenant = TenantHelpers.GetTenantByID(payload.ID, state.myTenants)
    if (tenant !== undefined) {
      tenant.LogoImage = 'data:image/png;base64,'.concat(payload.ImageData)
    }
  },
  updateMyTenants(state: any, tenants: Tenant[]) {
    for (const t of tenants) {
      TenantHelpers.SetTenantDefaultLogoIfNull(t)
      if (t.Subscription.SubscriptionFeatures && t.Subscription.SubscriptionFeatures.stats.jigStats.enabled == null) {
        t.Subscription.SubscriptionFeatures.stats.jigStats.enabled = false
      }
    }

    let existingTenantID = TenantHelpers.InvalidTenantID
    if (state.myTenantIndex !== TenantHelpers.InvalidTenantIndex) {
      existingTenantID = (state.myTenants[state.myTenantIndex] as Tenant).ID
    }
    state.myTenants = tenants
    let tenantIndex = state.myTenantIndex
    if (existingTenantID !== TenantHelpers.InvalidTenantID) {
      tenantIndex = ListHelpers.getIndexById(state.myTenants, 'ID', existingTenantID)
    }

    // If we have a saved tenant index from a previous session, use it here.
    if (state.myPreviousTenantIndex !== TenantHelpers.InvalidTenantIndex &&
      tenantIndex === TenantHelpers.InvalidTenantIndex &&
      state.myPreviousTenantIndex < state.myTenants.length) {
      tenantIndex = state.myPreviousTenantIndex
      state.myPreviousTenantIndex = TenantHelpers.InvalidTenantIndex
    }

    // If we haven't set a myTenantIndex, choose the tenant with the lowest
    // Tenant ID. In the future, we will most likely want to save the most recently
    // used dashboard tenant id for the given user and read that on login and use it below.
    if (tenantIndex === TenantHelpers.InvalidTenantIndex) {
      let lowestTenantId = Number.MAX_SAFE_INTEGER
      for (let i = 0; i < state.myTenants.length; i++) {
        const t: Tenant = state.myTenants[i]
        if (t.ID < lowestTenantId && t.Active) {
          lowestTenantId = t.ID
          tenantIndex = i
        }
      }
      if (lowestTenantId === Number.MAX_SAFE_INTEGER) {
        tenantIndex = tenants.length - 1
      }
    }

    const isLibraryDataResetRequired = _setMyTenantIndex(state, tenantIndex)
    if (isLibraryDataResetRequired) {
      this.resetLibraryData()
    }
  },
  updateMyMemberTenants(state: any, tenantsResponse: TenantToken[]) {
    state.myTenantTokens = tenantsResponse
  },
  updateMyTenantUsers(state: any, tenantUsers: TenantUser[]) {
    for (const tenantUser of tenantUsers) {
      tenantUser.extraPermissionNames = []
      if (Permissions.TenantUserHasPermissionAction(
        tenantUser, Permissions.PermTenant, PermissionActions.BillingContact)) {
        tenantUser.extraPermissionNames.push(TenantHelpers.ExtraPermissionBillingContact)
      }
      if (Permissions.TenantUserHasPermissionAction(
        tenantUser, Permissions.PermTenant, PermissionActions.Manage)) {
        tenantUser.extraPermissionNames.push(TenantHelpers.ExtraPermissionManager)
      }
    }
    state.tenantUsers = tenantUsers
    // Temp way to set tenantUserCount till new API endpoint is ready
    state.tenantUserCount = state.tenantUsers.filter((user: TenantUser) => user.roles && user.roles.find((role: String) => role === TenantHelpers.roleCreator1 || role === TenantHelpers.rolePresenter1)).length

    this.updateHelpHeroData(AppConst.HelpHero.helpHeroTenantUsersCountKey, tenantUsers.length)
  },
  setMyTenantIndexById(state: any, tenantId: number) {
    const tenantIndex = ListHelpers.getIndexById(state.myTenants, 'ID', tenantId)
    if (tenantIndex < state.myTenants.length && tenantIndex !== TenantHelpers.InvalidTenantIndex) {
      const isLibraryDataResetRequired = _setMyTenantIndex(state, tenantIndex)
      if (isLibraryDataResetRequired) {
        this.resetLibraryData()
      }
    } else {
      consoleLog('failed mutation setMyTenantIndexById')
    }
  },
  setMyTenantIndex(state: any, tenantIndex: number) {
    const isLibraryDataResetRequired = _setMyTenantIndex(state, tenantIndex)

    if (isLibraryDataResetRequired) {
      this.resetLibraryData()
    }
  },
  setMyPreviousTenantIndex(state: any, tenantIndex: number) {
    state.myPreviousTenantIndex = tenantIndex
  },
  updateTenantUserRoles(state: any, roles: UserRoleDefinition[]) {
    // TODO: CA-551 - Fix this issue on the server by removing RestrictToTenantIds values and remove this hack.
    // Hack in guest viewer role if it's not there
    let addGuestViewerRoleHack = true
    for (const r of roles) {
      if (r.Name === 'role_guestviewer1') {
        addGuestViewerRoleHack = false
        r.RestrictToTenantIds = []
        break
      }
    }
    if (addGuestViewerRoleHack) {
      roles.push({
        DisplayName: 'Guest Viewer',
        Name: 'role_guestviewer1',
        RestrictToTenantIds: [],
      })
    }
    state.tenantUserRoles = roles
    const tenantId = TenantHelpers.GetTenantID(state.myTenantIndex, state.myTenants)
    state.tenantUserRoleDisplayNames = []
    if (tenantId !== TenantHelpers.InvalidTenantID) {
      for (const r of state.tenantUserRoles) {
        state.tenantUserRoleDisplayNames.push(r.DisplayName)
      }
    }
  },
  updateTenantLogo(state: any, payload: TenantLogoImageResponse) {
    const tenant = TenantHelpers.GetTenantByID(payload.ID, state.tenants)
    if (tenant !== undefined) {
      tenant.LogoImage = 'data:image/png;base64,'.concat(payload.ImageData)
    }
  },
  updateTenantTotalJigCount(state: any, payload: any) {
    state.myTenantTotalJigCount = payload.data.count
  },
  updateTenantSetupJob(state: any, payload: TenantSetupJob) {
    state.tenantSetupJob = payload
  },
  updateTenants(state: any, tenantsResponse: TenantSearchResponse) {
    for (const t of tenantsResponse.rows) {
      TenantHelpers.SetTenantDefaultLogoIfNull(t)
    }

    state.tenants = tenantsResponse.rows
    state.totalTenantsQty = tenantsResponse.total_rows
    state.totalTenantsPages = tenantsResponse.total_pages
  },
  updateTenant(state: any, tenantResponse: Tenant) {
    for (let i = 0; i < state.tenants.length; i++) {
      const t = state.tenants[i] as Tenant
      if (t.ID === tenantResponse.ID) {
        if (tenantResponse.UserCount === undefined) {
          // Preserve UserCount if it's not in the response.
          tenantResponse.UserCount = t.UserCount
        }
        tenantResponse.LogoImage = t.LogoImage
        TenantHelpers.SetTenantDefaultLogoIfNull(tenantResponse)
        state.tenants[i] = Object.assign({}, tenantResponse)
        break
      }
    }
  },
  updateTenantUserCount(state: any, tenantUserCountData: TenantUserCountResponse) {
    state.tenantUserCount = tenantUserCountData.Data.count
  },
}
