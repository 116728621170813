import { TenantUser } from '@/store/modules/app/types'
import { AppConst } from '@/store/modules/constants'

export enum Namespace {
  App = 'app',
  Invites = 'invites',
  Jig = 'jig',
  TeamLibrary = 'teamlibrary',
  Utils = 'utils',
  Version = 'version',
}

export interface StandardObject {
  [key: string]: any
}

export interface Color {
  r: number
  g: number
  b: number
  a: number
}

export interface NullableTime {
  Time: Date
  Valid: boolean
}

export interface PermissionAddView {
  AudName: string
  AudType: AppConst.PermissionsAudienceType
  R: boolean
  U: boolean
  Valid: boolean
}

export interface InviteCreateParams {
  email: string
  resourceHashID: string // Either this or resourceID needs to be populated
  resourceID: number
  resourceType: AppConst.PermissionsResourceType
  permActions: PermAction[]
}

export interface InviteResource {
  resourceHashID: string // Either this or resourceID needs to be populated
  resourceID: number
  resourceType: AppConst.PermissionsResourceType
  permActions: PermAction[]
}

export interface Invite {
  id: number
  inviteeEmail: string
  inviterUserID: string
  tenantID: number
  inviteeRole: string
  resources: InviteResource[]
  outcomes: string[]
  status: string
  expiresAt: any
  createdAt: Date
  updatedAt: Date
  app: TargetApp
}

export interface InviteCreateRequest {
  invitation: Invite
}

export interface InviteCreateBulkRequest {
  requests: InviteCreateRequest[]
}

export interface InviteCreateResponse {
  invitation: Invite
  user: any
  tenantUser: TenantUser
}

export interface InviteCreateBulkItemResponse {
  response: InviteCreateResponse
  errorMsg: string
}

export interface InviteCreateBulkResponse {
  responses: InviteCreateBulkItemResponse[]
}

export interface HTTPRequest {
  action: AppConst.HTTPAction
  url: string
  body?: any
  params?: any
  responseType?: any
  cancelToken?: any
  baseURL?: string
}

export type TargetApp = 'viewer' | 'workshop' | 'jigspace'

export interface UploadThumbnailRequest {
  Id: string
  TenantId: number
  Thumbnail: File
  IsFirstThumbnail: boolean
  version?: number
  DateUpdated?: string
}

export interface UploadThumbnailResult {
  status: string
  thumbnailURL: string
}

export interface QueueIdResult {
  queueId: string // UUID
  originalFileName: string
  status: string
  message: string
}

export interface QueuedStatusResult {
  queueId: string // UUID
  processingType: string // 'undefined', 'astc_library_entry_thumbnail', 'obj_assimp' etc
  status: string // 'queued', 'in-progress', 'done', 'failed'
  originalFile: string
  files: FileGenerated[]
  params: any // map of string:string
  errorMessage: string
  wasRead: boolean
  dateCreated: string // golang time.Time
}

export interface FileGenerated {
  filename: string
  fileType: string
  fileExtension: string
  fileUrl: string
}

export interface ImageValidationResult {
  valid: boolean
  x: number
  y: number
  xMin: number
  xMax: number
  yMin: number
  yMax: number
}

export interface GatedFeature {
  name: string
  enforce: boolean
}

export interface Featuregate {
  enabled: boolean
  gatedFeatures?: {
    [key: string]: GatedFeature
  }
}

export interface SubscriptionFeatures {
  avp: Featuregate
  movo: Featuregate
  stats: JigStats
}

export interface JigStats {
  jigStats: Featuregate
}

export interface PermissionsMap {
  [name: string]: { [action: string]: any }
}

export type PermName = string
export type PermAction = 'view' | 'manage' | 'billing_contact' | 'login' | 'edit'
  | 'create' | 'delete' | 'copy' | 'small' | 'medium' | 'large' | 'offline'

export class PermissionActions {
  public static readonly View: PermAction = 'view'
  public static readonly Manage: PermAction = 'manage'
  public static readonly BillingContact: PermAction = 'billing_contact'
  public static readonly Login: PermAction = 'login'
  public static readonly Edit: PermAction = 'edit'
  public static readonly Create: PermAction = 'create'
  public static readonly Delete: PermAction = 'delete'
  public static readonly Copy: PermAction = 'copy'
  public static readonly Offline: PermAction = 'offline'
  public static readonly Small: PermAction = 'small'
  public static readonly Medium: PermAction = 'medium'
  public static readonly Large: PermAction = 'large'
}

export interface SecurityPermission {
  Name: PermName
  Actions: PermAction[]
}

export interface JigSummaryStats {
  id: number
  statisticDate: string
  application: string
  jigId: number
  jigName: string
  activity: string
  total: number
  durationSeconds: number
  duration: string
}

export interface ReportJigSummaryStats {
  activity: string
  author: string
  dataAvailable: boolean
  jigId: number
  jigName: string
  statisticDate: string
  total: number
  duration?: string
  durationSeconds?: number
}

export interface ReportJigHighlightStats {
  [key: string]: number
}

export interface JigActivityChartData {
  data: {
    datasets: Array<{
      backgroundColor?: string
      data?: any[]
      label?: string,
      borderColor?: string,
      pointBackgroundColor?: string,
      borderWidth?: number,
      pointBorderColor?: string,
    }>
    labels: any[]
  }
  options: {
    responsive: boolean
    maintainAspectRatio: boolean
    elements?: {
      point?: {
        radius?: number,
      },
    },
    title: {
      display: boolean
      text: string
    }
    legend: {
      display?: boolean
    },
    tooltips: {
      enabled: boolean
    },
    scales?: {
      xAxes?: [{
        type?: string,
        ticks?: {
          autoSkip?: boolean,
          autoSkipPadding?: number,
          maxRotation?: number,
          min?: number,
        },
      }],
      yAxes?: [{
        type?: string,
        ticks?: {
          autoSkip?: boolean,
          autoSkipPadding?: number,
          maxRotation?: number,
          min?: number,
        },
      }],
    },
  }
}

export interface TenantDashboardData {
  HighlightStats: ReportJigHighlightStats | null
  ComparisonHighlightStats?: ReportJigHighlightStats | null
  JigSummaryStats: ReportJigSummaryStats[] | null
  ComparisonJigSummaryStats?: ReportJigSummaryStats[] | null
}

export interface TenantDashboardReport {
  RequestByTenant: number
  RequestByUser: number
  ReportName: string
  ReportStartDate: string
  ReportEndDate: string
  ReportDays: number
  ProvideHighlightStats: boolean
  ProvideComparisons: boolean
  GenerateNullMissingValues: boolean
  Activities: string[]
  JigIds: number[]
  UsersIds: string[]
  Data: TenantDashboardData
}

export interface JigSummaryComparisonStats {
  JigSummaryStats: JigSummaryStats[]
  ComparisonProvided: boolean
  ComparisonTrend: number
}

export interface JigReportData {
  ReportName: string
  Data: any[]
}

export interface ReportRequest {
  Name: string
  Limit: number
  LookBackDays: number
  StartDate: string // API wants a Date string in RFC3339 nano format
  EndDate: string // API wants a Date string in RFC3339 nano format
  ComparePreviousPeriod: boolean
  JigHashId: string
}

export interface UploadTenantLogoRequest {
  TenantId: number
  Image: File
}

export interface SetupIntentResponse {
  stripeCustomerId: string
  stripeSetupIntentId: string
  stripeSetupIntentClientSecret: string
}

export interface CreateCheckoutResponse {
  CheckoutURL: string
}

export interface CreateCustomerPortalResponse {
  PortalURL: string
}

export interface DesktopVersion {
  ID: number
  Application: string
  Platform: string
  Name: string
  Archived: boolean
  CreatedAt: string
  UpdatedAt: string
  SupportStart: string
  SupportEnd: string
  ExpiryReminderStartOffsetHRS: number
  UpgradeCheckFrequencyHRS: number
  UpgradeReminderMessage: string
  Version: string
  Build: number
  downloadFileUri: string
}

export interface EmbedQueryDependsOn {
  name: string
  value: number
}

export interface EmbedQueryParams {
  name: string
  displayName: string
  defaultValue: string|number
  valueType: string
  minValue?: number
  maxValue?: number
  descriptionShort: string
  descriptionLong: string
  listOrder: number
  prefillName?: string
  dependsOnParam?: EmbedQueryDependsOn
}

export interface EmbedQueryParamObject {
  'en-US': Array<EmbedQueryParams>
}

export interface EmbedQueryParamConfig {
  defaultUrl: string
  defaultIframeEmbedHtml: string
  backlinkHtml: string
  queryParamDocs: EmbedQueryParamObject
}

export interface PricingTier {
  additionalSeatPrices?: any
  allowCustomBranding?: boolean
  allowJigActions: any
  allowJigStats?: boolean
  allowPasswordJigs?: boolean
  allowPrivateJigs?: boolean
  allowWorkshopPropertyPanel?: boolean
  displayName: string
  maxJigs ?: number
  maxJigsCountPer?: string
  initialCheckoutPrices?: any
  modelUploadMaxSizeMB: number
  name: string
  overLimitWarningDismissableUntil: string
  overLimitWarningShowContactUs: boolean
  restrictedJigCopyableByTypes: string[]
  seatsIncluded: number
  teamStorageLimitMB: number
  viewJigStepMax: number
}

export interface PricignListItem {
  name: string
  tiers: {
    [key:string]: PricingTier
  }
}

export interface DynaconfConfig {
  pricing: {
    sets: {
      [key: string]: PricignListItem
    }
  }
}

export interface SlackAttachment {
  title: string
  text: string
  color: string
  image_url: string
}

export interface SlackMessage {
  icon_emoji: string
  text: string
  attachments: SlackAttachment[]
}
