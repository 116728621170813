import { apiRequest } from '@/store/modules/ApiRequestHelper'
import { AppConst } from '@/store/modules/constants'
import { VersionConstants } from '@/store/modules/version/constants'
import { AppDownloadUrlsUpdateRequest, createVersionPromptPayload, Version } from '@/store/modules/version/types'

export default {
  // Submit new version entry
  async submitNewVersionEntry(context: any, payload: Version) {
    if (!payload || Object.keys(payload).length === 0) {
      return Promise.reject('invalid payload passed to submitNewVersionEntry, payload should be a Version item')
    } else {
      const mutationName = payload.Platform === `${VersionConstants.VersionDefaults.DefaultPlatform}` ? 'updateDesktopVersion' : 'updateIOSVersion'

      return apiRequest(
        {
          action: AppConst.HTTPAction.POST,
          url: `${AppConst.APIEndpoint.createNewVersionUrl}`,
          body: payload,
        },
        context,
        mutationName,
        `submit new version entry: ${payload}`
      )
    }
  },
  async getLatestVersionUrl(context: any, endpoint: string) {
    const mutationName: string = endpoint.indexOf(`${VersionConstants.VersionDefaults.DefaultPlatform}`) >= 0 ? 'updateDesktopVersion' : 'updateIOSVersion'

    return apiRequest(
      {
        action: AppConst.HTTPAction.GET,
        url: `${AppConst.APIEndpoint.versionUrl}/${endpoint}`,
        params: {},
      },
      context,
      mutationName,
      AppConst.Versions.versionErrorName
    )
  },
  createVersionPrompt(context: any, payload: createVersionPromptPayload) {
    context.commit(AppConst.StoreHelper.SetApiErrorMutationName,
      `There is no ${payload.platform} app version available, please create a minimum 3.11.0.0 ${payload.platform} version first.`, { root: true })
    context.commit(AppConst.StoreHelper.loadingMutationName, false, { root: true })
    context.commit(AppConst.StoreHelper.loadingModalMutationName, false, { root: true })
  },
  async updateBranchIODeeplinkDownloadUrl(context: any, payload: AppDownloadUrlsUpdateRequest) {
    if (!payload || Object.keys(payload).length === 0) {
      return Promise.reject('invalid payload passed to updateBranchIODeeplinkDownloadUrl, payload should be conforming to type AppDownloadUrlsUpdateRequest')
    } else {
      return apiRequest(
        {
          action: AppConst.HTTPAction.PUT,
          url: `${AppConst.APIEndpoint.branchIODownloadUrlsV1}`,
          body: payload,
        },
        context,
        '',
        `update BranchIO deeplink download URL with payload: ${payload}`
      )
    }
  },
}
