import { Version } from '@/store/modules/version/types'

export default {
  updateVersion(state: any, response: Version) {
    // Use a new version object to craft new state, in this way object change can be watched instantly.
    const newVersionState = {
      ...state.latestVersion,
      [response.Application]: response,
    }

    state.latestVersion = newVersionState
  },
  updateDesktopVersion(state: any, response: Version) {
    const newVersionState = {
      ...state.latestDesktopVersion,
      [response.Application]: response,
    }

    state.latestDesktopVersion = newVersionState
    state.latestVersion = newVersionState
  },
  updateIOSVersion(state: any, response: Version) {
    const newVersionState = {
      ...state.latestIOSVersion,
      [response.Application]: response,
    }

    state.latestIOSVersion = newVersionState
    state.latestVersion = newVersionState
  },
}
