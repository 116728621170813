// https://vuex.vuejs.org/en/modules.html
interface Dict { [key: string]: any }

const requireModule = require.context('.', true, /\.ts$/)
const modules: Dict = {}

requireModule.keys().forEach((fileName) => {
  if (fileName === './index.ts') { return; }

  // Replace ./ and .ts
  const path = fileName.replace(/(\.\/|\.ts)/g, '')
  const [moduleName, imported]: string[] = path.split('/')

  if (!modules[moduleName]) {
    modules[moduleName] = {
      namespaced: true,
    }
  }

  modules[moduleName][imported] = requireModule(fileName).default
})

export default modules

// This makes sure modules in sub directories are accessed through
// the appropriate namespace.
// EG usage in a component script, to access a prop of the app module:
// computed: {
//   ...mapState('utils', ['image', 'color']),
//   color(): any {
//     return this.$store.state.utils.color
//   }
// }
